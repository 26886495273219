import { Button, Typography } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../App";
import { AppStore } from "../../../AppStore";
import { ProductStatus } from "../../../components/productStatus/ProductStatus";
import { ProductStatus as ProductStatusState } from "../../../services/product/models/Product";
import { Product } from "../../../services/product/models/Product";
import styles from "../styles/Components.css";

interface Props {
  animated?: boolean;
  toggled?: boolean;
  products?: Product[];
  highlighted?: Product;
}

interface EntryProps {
  product: Product;
}

const Legend: FunctionComponent<Props> = (props) => {

  const mapProducts = (products) => {
    const productComponents: JSX.Element[] = [];

    if (props.products)
      products.forEach((product, index) => {
        const highlighted = props.highlighted == product;

        productComponents.push(
          <LegendEntry
            key={index}
            product={product}
          />
        );
      });

    return productComponents;
  };

  return (
    <div
      className={`${styles.legendContainer} ${
        props.animated ? styles.preload : ""
      } ${props.toggled ? styles.openBody : styles.closeBody}`}
    >
      <Typography
        fontWeight="bold"
        margin={1}
        align={"center"}
        variant={"subtitle1"}
      >
        Location Pending
      </Typography>
      <div className={styles.legendEntriesContainer} >
        {mapProducts(props.products)}
      </div>
    </div>
  );
};

const LegendEntry: FunctionComponent<EntryProps> = (props) => {
    
  const history = useHistory();
  const [productState, productActions] = AppStore.product.use();
  const { product } = props
  const [highlighted, setHighlighted] = useState(productState.highlightedProduct == product);

  const toDashboard = () => history.push(Routes.Dashboard);

  const handleSelect = () => {
    setHighlighted(true);
    productActions.setHighlightedProduct(product);
  };

  useEffect(() => {
      setHighlighted(productState.highlightedProduct == product)

  }, [productState.highlightedProduct])

  return (
    <div
      className={`${styles.productLegendKey} ${
        highlighted ? styles.highlighted : ""
      }`}
      onClick={handleSelect}
    >
      <ProductStatus
        status={
          product.status
        }
      />
      <Typography marginLeft={2} marginRight={3} variant={"body2"}>{product.serial}</Typography>
      { highlighted && <Button className={styles.legendButton} onClick={toDashboard}>Dashboard</Button>}
    </div>
  );
};

export default Legend;
