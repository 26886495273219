import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography } from "@mui/material";
import React from "react";
import { Routes } from "../../../App";
import styles from "../styles/LandingPage.css";

const Body = () => {
  const quoteStart = "If you are trying to use this portal, please reach out to techsupport@2btech.io";
  const instruction1 = "If you are trying to use this portal,";
  const instruction2 = "please reach out to techsupport@2btech.io";
  const instruction3 = "";
  const instruction4 = "";
  const instruction5 = "";

  return (
    <div className={`${styles.body} "fade-in-container"`}>
      <div className={styles.columnLeft}>
        <img
          className={styles.logo}
          height={400}
          width={400}
          src="./icons/2BTech_Logo-Stacked-PRINT.jpg"
        />

        <Typography variant="h3" className={styles.text}>
          {quoteStart}
        </Typography>
        </div>
      </div>
  );
};

export default Body;
