import { Product } from "../../services/product/models/Product";
import { BaseStore } from "../../utils/BaseStore";


export interface MapState {

}

export interface MapActions {

}

export class MapStore 
    extends BaseStore<MapState, MapActions> 
    implements MapActions {

    protected prototype = MapStore.prototype;
    protected initialState = {

    }

    constructor() {
        super();                
    }

}