import { AccountStore } from "./areas/account/AccountStore"
import { DashboardStore } from "./areas/dashboard/DashboardStore"
import { MapStore } from "./areas/map/MapStore"

import { DrawerStore } from "./components/drawer/DrawerStore"
import { DeviceStore } from "./providers/custom/DeviceStore"
import { ProductStore } from "./providers/custom/ProductStore"

const AppStore = {
    account: new AccountStore(),
    dashboard: new DashboardStore(),
    drawer: new DrawerStore(),
    product: new ProductStore(),
    device: new DeviceStore(),
    map: new MapStore()
}

export { AppStore }
