import { LatLng, LatLngBounds, Map } from "leaflet";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { Product } from "../../../../services/product/models/Product";
import ProductMapPoint from "../../../map/components/ProductMapPlot";
import { GridContext } from "../../layouts/GridManager";
import styles from '../../styles/Map.css'

interface Props {
  loading: boolean;
}

const UnitedStatesCenter = new LatLng(37.0902, -95.7129)


const MiniMapWrapper: FunctionComponent<Props> = (props) => {

  const [map, setMap] = useState<Map>();
  const [plot, setPlot] = useState<JSX.Element>();
  const [center, setCenter] = useState<LatLng>(UnitedStatesCenter);
  const [plotless, setPlotless] = useState(false)
  const { product, resizing } = useContext(GridContext)

  const plotProduct = (product: Product) => {
    setPlot(<ProductMapPoint visual product={product} />);
  };

  useEffect(() => {

    if (product && !product.coordinate.isDefault() && !product.coordinate.isFaulty()) {
      setCenter(new LatLng(product.coordinate.X, product.coordinate.Y));
      plotProduct(product)
      setPlotless(false)
    } else {
      setCenter(UnitedStatesCenter)
      setPlotless(true)
    }

  }, [product]);

  useEffect(() => {

    if (map)
      map.flyTo(center, plotless ? 5 : 10, {duration: 1, animate:false})

  }, [center])


  useEffect(() => {
    if (!map) return;

    map.flyTo(center, 10, {duration: 1, animate:false})

    map.zoomControl.setPosition("topright");
  }, [map]);

  useEffect(() => {
    if (!resizing)
      map?.invalidateSize();

  }, [resizing])

  return (
    <MapContainer
      zoom={5}
      whenCreated={setMap}
      center={center}
      scrollWheelZoom={false}
      className={styles.mapContainer}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {!plotless && plot}
    </MapContainer>
  );
};

export default MiniMapWrapper;
