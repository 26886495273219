// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.na_o524uVCXrs1yAb4FW {\n  width: 100%;\n  height: 100%;\n}\n\n@keyframes buTkfI205K7TKAeVZcgR {\n  0% {\n    fill-opacity: 1;\n  }\n  50% {\n    fill-opacity: 0;\n  }\n  100% {\n    fill-opacity: 1;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/areas/dashboard/styles/Map.css"],"names":[],"mappings":";AACA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":["\n.mapContainer {\n  width: 100%;\n  height: 100%;\n}\n\n@keyframes pulse {\n  0% {\n    fill-opacity: 1;\n  }\n  50% {\n    fill-opacity: 0;\n  }\n  100% {\n    fill-opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapContainer": "na_o524uVCXrs1yAb4FW",
	"pulse": "buTkfI205K7TKAeVZcgR"
};
export default ___CSS_LOADER_EXPORT___;
