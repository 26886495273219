import { Typography } from "@mui/material";
import React, { FunctionComponent, useContext, useState } from "react";
import { GridContext } from "../../layouts/GridManager";
import styles from "../../styles/Grid.css";

interface Props {}

const GridNavigationBar: FunctionComponent<Props> = (props) => {
  const [lockToggled, setLockToggled] = useState(true);
  const { product, toggleGridLock, toggleDownloadModal } = useContext(GridContext);

  const handleToggleLock = () => {
    toggleGridLock(!lockToggled);
    setLockToggled(!lockToggled);
  };

  const handleDownload = () => {
    toggleDownloadModal(true);
  };

  return (
    <div className={styles.gridNavbar}>
      <Typography variant="h5">Product: {product?.serial}</Typography>
      <div className={styles.iconContainer}>
        <div className={styles.iconButtonContainer} onClick={handleDownload}>
          <img src="./icons/download.svg" />
        </div>
        <div className={styles.iconButtonContainer} onClick={handleToggleLock}>
          {lockToggled ? (
            <img src="./icons/lock.svg" />
          ) : (
            <img src="./icons/unlock.svg" />
          )}
        </div>
      </div>
    </div>
  );
};

export default GridNavigationBar;
