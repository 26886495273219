import { AxiosRequestConfig } from "axios";
import { API } from "../../api";
import { ApiClient } from "../../utils/ApiClient";
import { UserDetails } from "./models/UserDetails";

export class AccountService {

    static addUser(user: UserDetails) {

        const requestConfig: AxiosRequestConfig = {
            method: "PUT",
            url: API.server.user.add,
            data: user
        };

        return ApiClient.request(requestConfig)
    }

    static removeUsers(userIds: number[]) {

        const requestConfig: AxiosRequestConfig = {
            method: "DELETE",
            url: API.server.user.removeMany,
            data: {"Ids": userIds}
        };

        return ApiClient.request(requestConfig)
    }


    public static getUserDetails(email: string) {

        const requestConfig: AxiosRequestConfig = {
            method: "GET",
            url: API.server.account.details.replace("%email%", email)
        };

        return ApiClient.request(requestConfig)
            .then(response => new UserDetails().copy(response.model))
    }
}