import { LatLng, LatLngBounds, Map } from "leaflet";
import React, { FunctionComponent, useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { AppStore } from "../../../AppStore";
import { Coordinate } from "../../../services/product/models/Coordinate";
import {
  Product,
  ProductAbbreviated,
} from "../../../services/product/models/Product";
import styles from "../styles/Map.css";
import ProductMapPoint from "./ProductMapPlot";

interface Props {
  loading: boolean;
  onFailedPlots: (plots: Product[]) => void;
}

const UnitedStatesCenter = new LatLng(37.0902, -95.7129);

const MapWrapper: FunctionComponent<Props> = (props) => {
  const [map, setMap] = useState<Map>();
  const [productState, productActions] = AppStore.product.use();
  const [bounds, setBounds] = useState<LatLngBounds>();
  const [centered, setCentered] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [zooming, setZooming] = useState(false);
  const [initialCoordinates, setInitialCoordinates] = useState<Coordinate[]>(
    []
  );

  const calculateMapCenter = (coordinates) => {
    if (coordinates.length > 0) {
      let [maxX, minX] = [coordinates[0].X, coordinates[0].X];
      let [maxY, minY] = [coordinates[0].Y, coordinates[0].Y];

      coordinates.forEach((coordinate) => {
        if (coordinate.X > maxX) maxX = coordinate.X;

        if (coordinate.X < minX) minX = coordinate.X;

        if (coordinate.Y > maxY) maxY = coordinate.Y;

        if (coordinate.Y < minY) minY = coordinate.Y;
      });

      const bounds: LatLngBounds = new LatLngBounds(
        new LatLng(minX, minY),
        new LatLng(maxX, maxY)
      );
      setBounds(bounds);
    }
  };

  const handleProducts = (products: Product[]) => {
    const coordinates: Coordinate[] = [];
    const filtered: Product[] = [];
    const failed: Product[] = [];

    products
      .filter((product: Product) => {
        if (
          productState.displayedProductType == ProductAbbreviated.All ||
          product.type == productState.displayedProductType
        )
          return product;
      })
      .forEach((product) => {
        const coord = product.coordinate;
        if (!coord.isDefault() && !coord.isFaulty()) {
          coordinates.push(coord);
          filtered.push(product);
        } else {
          failed.push(product);
        }
      });

    setFilteredProducts(filtered);
    setInitialCoordinates(coordinates);

    props.onFailedPlots(failed);
  };

  const plotProducts = () => {
    const plots: JSX.Element[] = [];

    if (filteredProducts.length > 0) {
      filteredProducts.map((product, index) => {
        plots.push(<ProductMapPoint product={product} key={index} />);
      });
    }

    return plots;
  };

  useEffect(() => {
    if (
      productState.products.length > 0 &&
      productState.products.length != filteredProducts.length
    )
      handleProducts(productState.products);
  }, [productState.products, productState.displayedProductType]);

  useEffect(() => {
    if (!props.loading) {
      calculateMapCenter(initialCoordinates);
      setZooming(true);
    }
  }, [props.loading]);

  useEffect(() => {
    if (
      !map ||
      !bounds ||
      (bounds.getCenter().lat == 0 && bounds.getCenter().lng == 0) ||
      centered
    )
      return;

    map.flyToBounds(bounds, { padding: [50, 50], duration: 1 });
    setCentered(true);
    setTimeout(() => setZooming(false), 1300);
  }, [bounds]);

  useEffect(() => {
    if (!map) return;

    map.zoomControl.setPosition("topright");
  }, [map]);

  return (
    <MapContainer
      zoom={5}
      whenCreated={setMap}
      center={UnitedStatesCenter}
      scrollWheelZoom={false}
      className={styles.mapContainer}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {!zooming && filteredProducts.length > 0 && plotProducts()}
    </MapContainer>
  );
};

export default MapWrapper;
