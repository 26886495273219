import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState
} from "react";
import ReactGridLayout from "react-grid-layout";
import { withSize } from "react-sizeme";
import { AppStore } from "../../../AppStore";
import { AQSync } from "../../../services/product/models/AQSync";
import { Product } from "../../../services/product/models/Product";
import styles from "../styles/Dashboard.css";
import gridStyles from "../styles/Grid.css";
import { DetailedProductGrid } from "./Grids/DetailedProductGrid";
import { SimpleProductGrid } from "./Grids/SimpleProductGrid";

export interface Layout {
  lg: LayoutItem[];
}

export interface LayoutItem {
  x: number;
  y: number;
  w: number;
  h: number;
  i: string;
  static: boolean;
}

interface GridManagerProps {
  onToggleDownloadModal: (toggle: boolean) => void;
}
interface IGridContext {
  product: Product | undefined;
  resizing: boolean;
  toggleDownloadModal: (toggle: boolean) => void;
  toggleGridLock: (toggle: boolean) => void;
}

const initialContext: IGridContext = {
  product: undefined,
  resizing: false,
  toggleDownloadModal: (toggle: boolean) => {},
  toggleGridLock: (toggle: boolean) => {},
};

export enum LayoutType {
  SimpleProduct = 0,
  DetailedProduct = 1,
}

interface ResizableGridProps {
  layout: any;
  onLayoutChange: (layout: any) => void;
  size: any;
}

const ResizableGrid: FunctionComponent<ResizableGridProps> = (props) => {
  return (
    <ReactGridLayout
      width={props.size.width}
      layout={props.layout}
      onLayoutChange={props.onLayoutChange}
      className={`${styles.grid} layout`}
      compactType={"vertical"}
      rowHeight={20}
      cols={12}
      margin={[15, 15]}
    >
      {props.children}
    </ReactGridLayout>
  );
};

export const GridContext = createContext<IGridContext>(initialContext);

const Grid = withSize({ monitorWidth: true })(ResizableGrid);

const GridManager: FunctionComponent<GridManagerProps> = (props) => {
  const [state, actions] = AppStore.dashboard.use();
  const [gridComponents, setGridComponents] = useState<JSX.Element[]>(
    DetailedProductGrid.components
  );
  const [drawerState, drawerActions] = AppStore.drawer.use();

  useEffect(() => {
    setGridContext({...gridContext, resizing: drawerState.animating})
  }, [drawerState.animating]);

  const handleToggleGridLock = (toggle) => {
    actions.setGridLocked(toggle);
  };

  const handleDownloadModal = (toggle) => {
    props.onToggleDownloadModal(toggle);
  };

  const handleLayoutChanged = (layout) => {
    actions.setLayout(layout);
  };

  const [gridContext, setGridContext] = useState<IGridContext>({
    ...initialContext,
    toggleGridLock: handleToggleGridLock,
    toggleDownloadModal: handleDownloadModal,
  });

  useEffect(() => {
    const newLayout = state.layout.map((item) => {
      return {
        ...item,
        static: state.gridLocked,
        isDraggable: !state.gridLocked,
      };
    });

    handleLayoutChanged(newLayout);
  }, [state.gridLocked]);

  useEffect(() => {
    const product = state.product;

    if (product) {
      if (product instanceof AQSync) {
        setGridComponents(DetailedProductGrid.components);
        actions.setLayoutType(LayoutType.DetailedProduct);
      } else {
        setGridComponents(SimpleProductGrid.components);
        actions.setLayoutType(LayoutType.SimpleProduct);
      }

      setGridContext({ ...gridContext, product: product });
    }
  }, [state.product?.formattedUpdatedAt, state.product?.status, state.product?.serial]);

  const createGridItem = (element: JSX.Element | undefined, index: number) => {
    return (
      <div key={index} className={gridStyles.gridItem}>
        {element}
      </div>
    );
  };

  return (
    <GridContext.Provider value={gridContext}>
      <Grid layout={state.layout} onLayoutChange={handleLayoutChanged}>
        {gridComponents?.map(createGridItem)}
      </Grid>
    </GridContext.Provider>
  );
};

export default GridManager;
