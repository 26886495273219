import { Button, Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import styles from "../styles/Components.css";

interface Props {
  onRemoveUsers: () => void;
  onAddUser: () => void;
  showButtons?: boolean;
}

const UserTableUtility: FunctionComponent<Props> = (props) => {
  const handleAddUser = () => {
    props.onAddUser();
  };

  const handleRemoveUsers = () => {
    props.onRemoveUsers();
  };

  return (
    <div className={styles.userTableUtilityBar}>
      <Typography variant="h4" fontWeight="bold">
        Users
      </Typography>
      {props.showButtons && (
        <div className={styles.tableUtilityButtons}>
          <Button variant="contained" color="error" onClick={handleRemoveUsers}>
            Remove
          </Button>
          <Button variant="contained" onClick={handleAddUser}>
            Add
          </Button>
        </div>
      )}
    </div>
  );
};

export default UserTableUtility;
