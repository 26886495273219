export class Coordinate {

    private x: number = 0;
    private y: number = 0;

    private accuracy: number = 0;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    public setAccuracy(accuracy: number) {
        this.accuracy = accuracy;
    }

    public get X() {
        return this.x ?? this.latitude;
    }

    public get Y() {
        return this.y ?? this.longitude;
    }

    public get longitude() {
        return this.y;
    }

    public get latitude() {
        return this.x;
    }

    public isDefault(): boolean {
        return this.y == 0 && this.x == 0;
    }

    public isFaulty(): boolean {
        return Math.round(this.y) == 1000 || Math.round(this.x) == 1000;
    }
}