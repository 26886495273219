import { Device, DeviceStatus } from "./Device"
import { DeviceUpload } from "./DeviceUpload"


export class PAM extends Device {

    constructor() {
        super()
    }

    public Update(update: DeviceUpload): void {

       

    }

    private HandleCoordinates(location: Location) {

        let [x, y] = [0, 0]

    }  

    private HasCoordinates(location: Location): boolean {

        return true
    }
}