import React, { FunctionComponent } from "react";
import { ProductStatus as ProductStatusModel } from "../../services/product/models/Product";
import styles from "./styles/ProductStatus.css";

interface Props {
  status: ProductStatusModel
  pulsed?: boolean
}

const ProductStatus: FunctionComponent<Props> = (props) => {
  
  return (
    <StatusObject
      status={props.status}
      className={`${styles.productStatus} 
      ${props.pulsed ? styles.pulse : ""}`}
    ></StatusObject>
  );
};

interface StatusObjectProps {
  status: string;
  className: string;
}

const StatusObject: FunctionComponent<StatusObjectProps> = (props) => {
  return <div {...props} />;
};

export { ProductStatus, StatusObject };
