import {
  Box,
  Divider,
  Drawer as Container,
  Toolbar,
  Typography
} from "@mui/material";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { useLocation } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { RouteResolver, Routes } from "../../App";
import { AppStore } from "../../AppStore";
import { AuthenticationContext } from "../../providers/AuthenticationProvider";
import { GlobalComponent } from "../../providers/GlobalComponentProvider";
import { ProductAbbreviated } from "../../services/product/models/Product";
import ProductList from "./components/ProductList";
import ProductSelect from "./components/ProductSelect";
import styles from "./styles/Drawer.css";

interface Props extends GlobalComponent {}

const Drawer: FunctionComponent<Props> = (props) => {
  const { pathname } = useLocation();
  const [state, actions] = AppStore.drawer.use();
  const [productState, productActions] = AppStore.product.use();
  const { authLevel: authLayer } = useContext(AuthenticationContext);
  const [productOptions, setProductOptions] = useState<ProductAbbreviated[]>(
    []
  );

  const handleSelectProductType = (type: ProductAbbreviated) => {
    productActions.setDisplayedProductType(type);
  };

  const handleInitialProducts = (products) => {
    if (products.length > 0) {
      products.forEach((product) => {
        const type = ProductAbbreviated[product.type.replace(/\s/g, "")];
        if (!productOptions.includes(type)) productOptions.push(type);
      });

      productOptions.push(ProductAbbreviated.All);

      setProductOptions(productOptions);
      handleSelectProductType(productOptions[productOptions.length - 1]);
    }
  };

  useEffect(() => {
    if (productOptions.length == 0)
      handleInitialProducts(productState.products);
  }, [productState.products]);


  if (!props.renderCondition(authLayer, Routes[RouteResolver[pathname]]))
    return null;

  return (
    <Container
      variant="persistent"
      open={state.drawerToggled}
      className={styles.container}
      PaperProps={{ className: styles.paper }}
    >
      <Toolbar />
      {productOptions.length > 0 ? (
        <>
          <div className={styles.drawerHeader}>
            <ProductSelect
              product={productState.displayedProductType}
              onSelectProductType={handleSelectProductType}
              productOptions={productOptions}
            />
          </div>
          <Box sx={{ overflow: "auto" }}>
            <Divider />
            <ProductList
              products={productState.products}
              filter={
                productState.displayedProductType == ProductAbbreviated.All
                  ? ""
                  : productState.displayedProductType
              }
            />
          </Box>
        </>
      ) : (
        <div className={styles.loaderContainer}>
          {productState.failedToLoadProducts ? (
            <Typography variant="h6">Could not find any products...</Typography>
          ) : (
            <SyncLoader loading={true} color="grey" />
          )}
        </div>
      )}
    </Container>
  );
};

export default Drawer;
