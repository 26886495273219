import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppStore } from "../../AppStore";
import { WebSocketContext } from "../../providers/WebSocketProvider";
import { Product, ProductStatus } from "../../services/product/models/Product";
import { Upload } from "../../services/product/models/ProductUpload";
import { ProductService } from "../../services/product/ProductService";

interface Props {}

const ProductProvider: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.product.use();
  const { service, connected } = useContext(WebSocketContext);
  const { productUpdates } = service.connections;
  const [syncProducts, setSyncProducts] = useState(false);

  const updateProducts = (products: Product[]) => {
    if (products.length > 0)
      actions.setProducts(products);
  };


  const handleFailedToLoadProducts = (error) => {
    console.log("Failed to fetch products...");
    actions.setFailedToLoadProducts(true)
  };

  const handleReconnecting = (reconncting) => {
    actions.setReconnecting(reconncting)
  }

  const expireProductSync = () => {
    if (state.products.length > 0) {
      const newProducts: Product[] = [];

      state.products.forEach((product) => newProducts.push(product));
      newProducts.forEach((product) => {
        if (product.status == ProductStatus.Loading) {
          product.status = ProductStatus.Unhealthy;
        }
      });

      updateProducts(newProducts);
    } else {
      handleFailedToLoadProducts("");
    }
  };

  const handleProductUpdate = (update: Upload) => {
    console.log(update);

    const prods: Product[] = [];

    state.products.forEach((product) => prods.push(product));

    prods.forEach((product) => {
      if (update.productId == product.id) product.Update(update);
    });

    updateProducts(prods);
  };

  useEffect(() => {
    if (syncProducts) {

      ProductService.getRecentAQSyncUploads()
        .then((uploads: Upload[]) => {
          uploads.forEach((upload: Upload) => {
            handleProductUpdate(upload);
          });
        })
        .catch(console.log);

        ProductService.getRecentAQLiteUploads()
        .then((uploads: Upload[]) => {
          uploads.forEach((upload: Upload) => {
            handleProductUpdate(upload);
          });
        })
        .catch(console.log);

        ProductService.getRecentPAMUploads()
        .then((uploads: Upload[]) => {
          uploads.forEach((upload: Upload) => {
            handleProductUpdate(upload);
          });
        })
        .catch(console.log)

      productUpdates.on("AQSyncUpload", handleProductUpdate);
      productUpdates.on("AQLiteUpload", handleProductUpdate);
      productUpdates.on("PAMUpload", handleProductUpdate);

      productUpdates.onreconnecting(error => handleReconnecting(true));
      productUpdates.onreconnected(conn => handleReconnecting(false));

      setTimeout(expireProductSync, 10000);
    }
  }, [syncProducts]);

  useEffect(() => {
    if (connected) {
      ProductService.getProducts()
        .then(updateProducts)
        .catch(handleFailedToLoadProducts)
        .finally(() => setSyncProducts(true));
    }
  }, [connected]);

  return <>{props.children}</>;
};

export { ProductProvider };
