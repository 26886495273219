import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppStore } from "../../AppStore";
import { WebSocketContext } from "../WebSocketProvider";
import { DeviceService } from "../../services/device/DeviceService";
import { Device, DeviceStatus } from "../../services/device/models/Device";
import { DeviceUpload } from "../../services/device/models/DeviceUpload";

interface Props {}

const DeviceProvider: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.device.use();
  const [productState, productActions] = AppStore.product.use();
  const { service, connected } = useContext(WebSocketContext);
  const { productUpdates } = service.connections;
  const [syncDevices, setSyncDevices] = useState(false);

  const updateDevices = (devices) => {
    actions.setDevices(devices);
  };

  const handleDevices = (devices) => {
    if (devices.length > 0) {
      updateDevices(devices);
    }
  };

  const handleFailedToLoadDevices = (error) => {
    console.log("Failed to fetch devices...");
    actions.setFailedToLoadDevices(true)
  };

  const expireDeviceSync = () => {
    if (state.devices.length > 0) {
      const newDevices: Device[] = [];

      state.devices.forEach((device) => newDevices.push(device));
      newDevices.forEach((device) => {
        if (device.status == DeviceStatus.Loading) {
          device.status = DeviceStatus.Unhealthy;
        }
      });

      updateDevices(newDevices);
    } else {
      handleFailedToLoadDevices("");
    }
  };

  const handleDeviceUpdate = (update: DeviceUpload) => {
    console.log(update);

    const devs: Device[] = [];

    state.devices.forEach((device) => devs.push(device));

    devs.forEach((device) => {
      if (update.serial == device.serial) device.Update(update);
    });

    updateDevices(devs);
  };

  useEffect(() => {
    if (syncDevices) { 
      DeviceService.getRecentUploads()
        .then((uploads: DeviceUpload[]) => {
          uploads.forEach((upload: DeviceUpload) => {
            handleDeviceUpdate(upload);
          });
        })
        .catch((err) => {});

      setTimeout(expireDeviceSync, 10000);
    }
  }, [syncDevices]);

  useEffect(() => {

    const product = productState.highlightedProduct;

    // if (product) {
    //   DeviceService.getDevicesBySerial(product.serial)
    //     .then(handleDevices)
    //     .catch(handleFailedToLoadDevices)
    //     .finally(() => setSyncDevices(true));
    // }
  }, [productState.highlightedProduct]);

  return <>{props.children}</>;
};

export { DeviceProvider };
