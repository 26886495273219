import { FunctionComponent, useState } from "react";
import React from "react";
import {
  DataGrid,
  GridCallbackDetails,
  GridEventListener,
  GridEvents,
  GridSelectionModel,
} from "@mui/x-data-grid";
import styles from "../styles/Components.css";
import UserTableUtility from "./UserTableUtility";
import Popup from "../../../components/popup/Popup";
import { UserDetails } from "../../../services/account/models/UserDetails";
import AddUserForm from "../forms/AddUserForm";
import { AccountService } from "../../../services/account/AccountService";
import { Button } from "@mui/material";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "Name", width: 160 },
  { field: "email", headerName: "Email", width: 210 },
  {
    field: "isAdmin",
    headerName: "Administrator",
    type: "boolean",
    width: 130,
  },
  {
    field: "signupAt",
    headerName: "Created",
    type: "number",
    width: 190,
  },
];

interface Props {
  addControls?: boolean;
  users?: UserDetails[];
  onRefresh: () => void;
}

const UserTable: FunctionComponent<Props> = (props) => {
  const [selectedUserIds, setSelectedUserIds] = useState<any[]>([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const handleSelections = (
    selectionModel: GridSelectionModel,
    _: GridCallbackDetails
  ) => {
    if (props.users) setSelectedUserIds(selectionModel);
  };

  const handleAddUser = () => {
    setShowAddUserModal(true);
  };

  const handleCloseAddUserModal = (refresh: boolean) => {
    setShowAddUserModal(false);

    if (refresh) props.onRefresh();
  };

  const handleCloseConfirmDeleteModal = (refresh: boolean) => {
    setShowConfirmDeleteModal(false);

    if (refresh) props.onRefresh();
  };

  const handleRemoveUsers = () => {

    if (selectedUserIds.length > 0)
      setShowConfirmDeleteModal(true);
  };

  return (
    <>
      <Popup
        title={`Add New User`}
        visible={showAddUserModal}
        onDismiss={() => handleCloseAddUserModal(false)}
      >
        <AddUserForm onClose={handleCloseAddUserModal} />
      </Popup>
      <Popup
        visible={showConfirmDeleteModal}
        onDismiss={() => handleCloseConfirmDeleteModal(false)}
      >
        <ConfirmDeleteModal
          onClose={handleCloseConfirmDeleteModal}
          selectedUserIds={selectedUserIds}
        />
      </Popup>
      <UserTableUtility
        onAddUser={handleAddUser}
        onRemoveUsers={handleRemoveUsers}
        showButtons={props.addControls}
      />
      <div className={styles.tableContainer}>
        <DataGrid
          rows={props.users ? props.users : []}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection={props.addControls}
          onSelectionModelChange={handleSelections}
        />
      </div>
    </>
  );
};

export default UserTable;
