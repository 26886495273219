import React, { FunctionComponent } from "react";
import { Route } from "react-router-dom";
import "./App.css";
import Company from "./areas/company/layouts";
import Dashboard from "./areas/dashboard/layouts";
import LandingPage from "./areas/landing/layouts";
import Map from "./areas/map/layouts";
import Drawer from "./components/drawer/Drawer";
import NavigationBar from "./components/navigationbar/NavigationBar";
import { RenderCondition } from "./helpers/renderConditions";
import { DeviceProvider } from "./providers/custom/DeviceProvider";
import { ProductProvider } from "./providers/custom/ProductProvider";
import { WithRenderCondition } from "./providers/GlobalComponentProvider";
import { ProviderStack } from "./providers/ProviderStack";
import { AdminRoute, ProtectedRoute } from "./providers/RouterProvider";
import { useHealthCheck } from "./utils/Hooks";

export enum Routes {
  Dashboard = "/dashboard",
  Company = "/company",
  Landing = "/landing",
  Profile = "/profile",
  Admin = "/admin",
  Login = "/login",
  Map = "/map",
}

export const RouteResolver: Record<Routes, string> = {
  "/dashboard": "Dashboard",
  "/company": "Company",
  "/landing": "Landing",
  "/profile": "Profile",
  "/admin": "Admin",
  "/login": "Login",
  "/map": "Map",
};
 
const App: FunctionComponent = (props) => {
  const onSuccessfulHealthCheck = () => {};
  useHealthCheck(onSuccessfulHealthCheck);

  return (
    <ProviderStack
      defaultSecureRoute={Routes.Map}
      defaultAdminRoute={Routes.Map}
      defaultInsecureRoute={Routes.Landing}
      navigationComponent={NavigationBar}
      globalProviders={[
        ProductProvider,
        DeviceProvider
      ]}
      globalComponents={[
        WithRenderCondition(
          Drawer,
          RenderCondition.basicAuthentication
        ),
      ]}
    >
      {/* <AdminRoute exact path={Routes.Admin} component={Dashboard} /> */}
      <ProtectedRoute exact path={Routes.Dashboard} component={Dashboard} />
      <ProtectedRoute exact path={Routes.Company} component={Company} />
      <ProtectedRoute exact path={Routes.Map} component={Map} />
      <Route exact path={Routes.Landing} component={LandingPage}></Route>
    </ProviderStack>
  );
};

export { App };
