import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import Plot from "react-plotly.js";
import { AQSync } from "../../../../services/product/models/AQSync";
import { Product } from "../../../../services/product/models/Product";
import {
  ProductService,
  ProductUtility,
} from "../../../../services/product/ProductService";
import { SensorColor } from "../../../../theme/colors";
import Loading from "../../../account/components/Loading";
import { GridContext } from "../../layouts/GridManager";

interface Props {}

const initialLayout = {
  responsive: true,
  autosize: true,
  width: "100%",
  paper_bgcolor: "rgba(0,0,0,0)",
  plot_bgcolor: "rgba(0,0,0,0)",
  xaxis: {
    autorange: true,
    rangeselector: {
      buttons: [
        {
          count: 7,
          label: "week",
          step: "day",
        },
      ],
    },
    type: "date",
  },
  yaxis: {
    autorange: true,
    type: "linear",
  },
};

const ProductGraph: FunctionComponent<Props> = (props) => {
  const [rawGraph, setRawGraph] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [previousProduct, setPreviousProduct] = useState<Product | undefined>();
  const [previousData, setPreviousData] = useState<any[]>();
  const [figure, setFigure] = useState<any>({
    data: [],
    layout: initialLayout,
    frames: [],
    config: {},
  });
  const { product, resizing: resizing } = useContext(GridContext);

  const handleUpdate = (update) => {
    setPreviousData(update.data);
    setFigure(update);
  };

  const handleFailedToLoadProducts = (error) => {
    console.log("Failed to fetch product uploads");
    console.log(error);
    setRawGraph({});
    setLoading(false);
  };

  const handlePreprocessUploads = (uploads) => {
    let graph = {};

    if (product instanceof AQSync)
      graph = ProductUtility.preProcessDetailedGraphData(uploads);
    else graph = ProductUtility.preProcessSimpleGraphData(uploads);

    console.log(graph)
    setRawGraph(graph);
  };

  useEffect(() => {
    if (product != previousProduct) {
      setPreviousData(undefined);
      setLoading(true);
    }

    if (product) {
      const date = new Date();
      const startDate = new Date(new Date().setDate(date.getDate() - 7));

      ProductService.getProductUploads(product, startDate, date)
        .then(handlePreprocessUploads)
        .catch(handleFailedToLoadProducts);
    }

    setPreviousProduct(product);
  }, [product?.formattedUpdatedAt]);

  useEffect(() => {
    if (rawGraph) {
      const graph = Object.keys(rawGraph).map((entry) => {
        if (
          entry === "Latitude" ||
          entry === "Longitude" ||
          entry === "WindDirection"
        ) {
          return {};
        }

        let visible =
          previousData &&
          previousData.includes((item) => item.name == entry) &&
          previousData.length > 0
            ? previousData.find((item) => item.name == entry).visible
            : rawGraph[entry]["visible"];

        return {
          x: rawGraph[entry]["time"],
          y: rawGraph[entry]["sample"],
          name: entry,
          type: "scattergl",
          mode: "lines+markers",
          visible: visible,
          marker: {
            color: SensorColor[entry],
            size: 5,
          },
        };
      });

      setFigure({ ...figure, data: graph });
      setLoading(false);
    }
  }, [rawGraph]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : !resizing ? (
        <Plot
          style={{ width: "100%", height: "100%" }}
          useResizeHandler={true}
          onUpdate={handleUpdate}
          {...figure}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default ProductGraph;
