import { BaseStore } from "../../utils/BaseStore";
import { Product as ProductModel, ProductAbbreviated, ProductType } from "../../services/product/models/Product"
import { load } from "dotenv";


export interface ProductState {
    products: ProductModel[]
    highlightedProduct: ProductModel | undefined
    failedToLoadProducts: boolean
    reconnecting: boolean
    displayedProductType: ProductAbbreviated
}

export interface ProductActions {
    setProducts(prodcts: ProductModel[]): void
    setHighlightedProduct(product: ProductModel | undefined): void
    setFailedToLoadProducts(failed: boolean): void
    setReconnecting(reconnecting: boolean): void
    setDisplayedProductType(type: ProductAbbreviated): void
}

export class ProductStore 
    extends BaseStore<ProductState, ProductActions> 
    implements ProductActions {

    protected prototype = ProductStore.prototype;
    protected initialState = {
        products: [],
        highlightedProduct: undefined,
        failedToLoadProducts: false,
        reconnecting: false,
        displayedProductType: ProductAbbreviated.All
    }

    constructor() {
        super();        
    }

    public setProducts(products: ProductModel[]) {
        this.setState({
            ...this.state,
            products: products
        })
    }

    public setHighlightedProduct(product: ProductModel) {
        this.setState({
            ...this.state,
           highlightedProduct: product
        })
    }

    public setFailedToLoadProducts(failed: boolean) {
        this.setState({
            ...this.state,
           failedToLoadProducts: failed
        })
    }

    public setReconnecting(reconnecting: boolean) {
        this.setState({
            ...this.state,
           reconnecting: reconnecting
        })
    }

    public setDisplayedProductType(type: ProductAbbreviated) {
        this.setState({
            ...this.state,
           displayedProductType: type
        })
    }
}