import { Sensors } from "@mui/icons-material";
import { isUTCOffset } from "../../../helpers/time";
import {
  DeviceAbbreviation,
  DeviceSensor,
  SensorAbbreviation,
} from "../../device/models/Device";
import { Coordinate } from "./Coordinate";
import {
  Product,
  ProductAbbreviated,
  ProductStatus,
  ProductUploadExpiration,
  SimpleProduct,
} from "./Product";
import {
  DeviceSensors,
  Measurement,
  ProductData,
  SimpleProductUpload,
  Upload,
} from "./ProductUpload";

export class AQLite extends Product implements SimpleProduct {
  constructor() {
    super();

    this.type = ProductAbbreviated.AQLite
  }

  lastUpload: SimpleProductUpload | undefined;

  static sensors = [
    DeviceSensor.Battery,
    DeviceSensor.CO,
    DeviceSensor.CO2,
    DeviceSensor.NO2,
    DeviceSensor.Ozone,
    DeviceSensor.PM1,
    DeviceSensor.PM2_5,
    DeviceSensor.PM10,
    DeviceSensor.Press,
    DeviceSensor.RHum,
    DeviceSensor.Temp,
  ];

  public Update(update: SimpleProductUpload): void {
    this.status = update.status
      ? ProductStatus[update.status]
      : ProductStatus.Loading;

    super.updateTime(update.deviceTime);

    if (this.isMissingDevices(update.points)) {
      this.status = ProductStatus.Moderate;
      this.statusReason = "One or more devices not publishing"
    }

    super.handleHealth(update.deviceTime, ProductUploadExpiration.AQLITE);

    this.HandleCoordinates(update.location);

    this.lastUpload = update;
  }

  private HandleCoordinates(coordinate: Coordinate) {
    let [x, y] = [coordinate.latitude, coordinate.longitude];

    this.coordinate = new Coordinate(x, y);
  }

  private isMissingDevices(data: DeviceSensors): boolean {
    let missing = false;

    AQLite.sensors.forEach((sensor) => {
      if (data[sensor ?? ""] == undefined) missing = true;
    });

    return missing;
  }
}
