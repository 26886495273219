import { InputBase, MenuItem, Select } from "@mui/material";
import React, { FunctionComponent } from "react";
import styles from "../styles/Drawer.css";
import {
  ProductAbbreviated,
  ProductTranslation as ProductAbbreviator,
} from "../../../services/product/models/Product";

interface Props {
  onSelectProductType: (product: ProductAbbreviated) => void;
  product: ProductAbbreviated;
  productOptions: ProductAbbreviated[];
}

const ProductSelect: FunctionComponent<Props> = (props) => {

  return (
    <Select
      value={props.product}
      className={styles.productSelect}
      input={<InputBase className={styles.productSelectInput} />}
      inputProps={{ className: styles.downArrowSelector }}
      MenuProps={{ className: styles.selectorMenu }}
      onChange={(event) =>
        props.onSelectProductType(ProductAbbreviated[event.target.value.replace(/\s/g, "")])
      }
    >
      {props.productOptions.map((product: ProductAbbreviated, index: number) => (
        <MenuItem key={index} value={product}>
          {product}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ProductSelect;
