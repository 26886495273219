import { Product } from "../../services/product/models/Product";
import { BaseStore } from "../../utils/BaseStore";
import { Layout, LayoutItem, LayoutType  } from "./layouts/GridManager";
import { DetailedProductGrid } from "./layouts/Grids/DetailedProductGrid";
import { SimpleProductGrid } from "./layouts/Grids/SimpleProductGrid";


export interface DashboardState {
    product: Product | undefined
    previousProduct: Product | undefined
    layout: LayoutItem[]
    previousDetailedProductLayout: LayoutItem[]
    gridLocked: boolean
    previousSimpleProductLayout: LayoutItem[]
    layoutType: LayoutType
}

export interface DashboardActions {
    setProduct(product: Product | undefined): void
    setPreviousProduct(product: Product | undefined): void
    setLayout(layout: LayoutItem[]): void
    setPreviousDetailedProductLayout(layout: LayoutItem[]): void
    setPreviousSimpleProductLayout(layout: LayoutItem[]): void
    setGridLocked(locked: boolean): void
    setLayoutType(type: LayoutType): void
}

export class DashboardStore 
    extends BaseStore<DashboardState, DashboardActions> 
    implements DashboardActions {

        protected prototype = DashboardStore.prototype;
        protected initialState = {
            product: undefined,
            previousProduct: undefined,
            layout: DetailedProductGrid.layout,
            previousDetailedProductLayout: DetailedProductGrid.layout,
            previousSimpleProductLayout: SimpleProductGrid.layout,
            gridLocked: true,
            layoutType: LayoutType.DetailedProduct
        }
    
        constructor() {
            super();                
        }
    
        public setProduct(product: Product) {
            this.setState({
                ...this.state,
                previousProduct: this.state.product,
                product: product
            })
        }

        public setPreviousProduct(product: Product) {
            this.setState({
                ...this.state,
                previousProduct: product
            })
        }

        public setLayout(layout: LayoutItem[]) {

            if (this.state.layoutType == LayoutType.SimpleProduct)
                this.setState({
                    ...this.state,
                    layout: layout,
                    previousSimpleProductLayout: layout
                })
            else 
                this.setState({
                    ...this.state,
                    layout: layout,
                    previousDetailedProductLayout: layout
                })
        }
            
        public setPreviousDetailedProductLayout(layout: LayoutItem[]) {
            this.setState({
                ...this.state,
                previousDetailedProductLayout: layout
            })
        }

        public setPreviousSimpleProductLayout(layout: LayoutItem[]) {
            this.setState({
                ...this.state,
                previousSimpleProductLayout: layout
            })
        }

        public setGridLocked(locked: boolean) {
            this.setState({
                ...this.state,
                gridLocked: locked
            })
        }

        public setLayoutType(type: LayoutType) {

            if (type == LayoutType.SimpleProduct)
                this.setState({
                    ...this.state,
                    layout: this.state.previousSimpleProductLayout,
                    layoutType: type
                })
            else 
                this.setState({
                    ...this.state,
                    layout: this.state.previousDetailedProductLayout,
                    layoutType: type
                })
        }
}