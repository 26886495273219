import { Backdrop, Box, Typography } from "@mui/material";
import ModalUnstyled from '@mui/base/ModalUnstyled';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppStore } from "../../../AppStore";
import {
  Product,
  ProductStatus,
} from "../../../services/product/models/Product";
import Legend from "../components/Legend";
import MapWrapper from "../components/Map";
import styles from "../styles/Map.css";

interface Props {}

const Body: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.map.use();
  const [drawerState, drawerActions] = AppStore.drawer.use();
  const [productState, productActions] = AppStore.product.use();
  const [loadingDevices, setLoadingDevices] = useState(true);
  const [animationsBuffered, setAnimationsBuffered] = useState(true);
  const [failedPlots, setFailedPlots] = useState<Product[]>([])

  const handleFailedPlots = plots => {
    setFailedPlots(plots)
  }

  useEffect(() => {
    if (productState.products.length > 0) {
      let loading: boolean = false;

      productState.products.forEach((product) => {
        if (product.status == ProductStatus.Loading) {
          loading = true;
        }
      });

      setLoadingDevices(loading);
    }
  }, [productState.products]);

  useEffect(() => {
    if (productState.failedToLoadProducts) setLoadingDevices(false);
  }, [productState.failedToLoadProducts]);

  useEffect(() => {
    setTimeout(() => setAnimationsBuffered(false), 500);
  }, []);

  return (
    <div
      className={`${styles.body} ${animationsBuffered ? styles.preload : ""} ${
        drawerState.drawerToggled ? styles.openBody : styles.closeBody
      }`}
    >
      <MapWrapper onFailedPlots={handleFailedPlots} loading={loadingDevices} />
      <ModalUnstyled
        // BackdropProps={{ className: styles.modalContainer }}
        // BackdropComponent={Backdrop}
        open={loadingDevices}
      >
        <Box className={styles.connectingDevicesModal}>
          <Typography variant="h6">Locating your devices...</Typography>
        </Box>
      </ModalUnstyled>
      <Legend
        animated={animationsBuffered}
        toggled={drawerState.drawerToggled}
        products={failedPlots}
        highlighted={productState.highlightedProduct}
      />
    </div>
  );
};

export default Body;
