import { Typography } from "@mui/material";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { SyncLoader } from "react-spinners";
import {
  DeviceAbbreviation,
  SensorAbbreviation,
} from "../../../../services/device/models/Device";
import {
  DetailedProduct,
  Product,
} from "../../../../services/product/models/Product";
import {
  DetailedProductUpload,
  DeviceWrapper,
  Measurement,
} from "../../../../services/product/models/ProductUpload";
import { GridContext } from "../../layouts/GridManager";
import styles from "../../styles/Components.css";

interface Props {
  device: DeviceAbbreviation;
}

interface MeasurementProps {
  measurement?: Measurement;
  scale: number;
}

const color = "#220088";

const AQSyncDatapoint: FunctionComponent<Props> = (props) => {
  const [deviceMeasurements, setDeviceMeasurements] =
    useState<JSX.Element[] | undefined>();
  const { product } = useContext(GridContext);

  const mapDeviceMeasurements = (product: Product) => {
    const filtered: [string, DeviceWrapper] | undefined = (
      product as DetailedProduct
    ).lastUpload
      ? Object.entries(
          ((product as DetailedProduct).lastUpload as DetailedProductUpload)
            .data
        ).find((item) => DeviceAbbreviation[item[0]] == props.device)
      : undefined;

    if (filtered) {
      const [_, device] = filtered;

      const measurements = device.measurements.map((measurement, index) => (
        <MeasurementComponent
          key={index}
          measurement={measurement}
          scale={1 / Math.cbrt(device.measurements.length)}
        />
      ));

      setDeviceMeasurements(measurements);
    } else {
      setDeviceMeasurements(undefined);
    }
  };

  useEffect(() => {
    if (product) mapDeviceMeasurements(product);
  }, [(product as DetailedProduct)?.lastUpload]);

  return (
    <div className={styles.dataPointContainer}>
      {withFormatting(
        deviceMeasurements,
        deviceMeasurements ? deviceMeasurements.length : 0
      )}
      <Typography color={color} fontWeight="bold" variant="body1">
        {props.device}
      </Typography>
    </div>
  );
};

const MeasurementComponent: FunctionComponent<MeasurementProps> = (props) => {
  return (
    <div
      style={{
        height: props.scale * 120,
        width: props.scale * 120,
      }}
      className={styles.dataPointCircleContainer}
    >
      <div
        style={{
          borderColor: color,
        }}
        className={styles.dataPointCircle}
      >
        <div className={styles.dataPointValueContainer}>
          <Typography
            style={{
              color: color,
            }}
            variant="body2"
            className={styles.dataPointValue}
          >
            {props.measurement?.reading.toString().substring(0, 6)}
            <br />
            {props.measurement?.units ?? ""}
          </Typography>
          <div className={styles.dataPointCircleBreak}>
            <Typography
              style={{
                color: color,
              }}
              variant="body2"
              className={styles.dataPointUnits}
            >
              {props.measurement
                ? SensorAbbreviation[props.measurement.sensor]
                : ""}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AQSyncDatapoint;

function withFormatting(Components: JSX.Element[] | undefined, format: number) {
  const handleFormat = (): JSX.Element | JSX.Element[] | undefined => {
    if (!Components) {
      return (
        <div className={styles.dataPointFormat}>
          <SyncLoader loading={true} color={color} />
        </div>
      );
    } else {
      switch (format) {
        case 1:
          break;
        case 2:
          break;
        case 3:
          return (
            <div className={styles.dataPointFormat}>
              <div className={styles.dataPointLayer}>
                {Components.at(0)} {Components.at(1)}
              </div>
              <div className={styles.dataPointLayer}>{Components.at(2)}</div>
            </div>
          );
        case 4:
          return (
            <div className={styles.dataPointFormat}>
              <div className={styles.dataPointLayer}>
                {Components.at(0)} {Components.at(1)}
              </div>
              <div className={styles.dataPointLayer}>
                {Components.at(2)} {Components.at(3)}
              </div>
            </div>
          );
        case 5:
          return (
            <div className={styles.dataPointFormat}>
              <div className={styles.dataPointLayer}>
                {Components.at(0)} {Components.at(1)}
              </div>
              <div className={styles.dataPointLayer}>
                {Components.at(2)} {Components.at(3)}
              </div>
              <div className={styles.dataPointLayer}>{Components.at(4)}</div>
            </div>
          );
        case 6:
          return (
            <div className={styles.dataPointFormat}>
              <div className={styles.dataPointLayer}>
                {Components.at(0)} {Components.at(1)}
              </div>
              <div className={styles.dataPointLayer}>
                {Components.at(2)} {Components.at(3)}
              </div>
              <div className={styles.dataPointLayer}>
                {Components.at(4)} {Components.at(5)}
              </div>
            </div>
          );
        default:
          break;
      }

      return (
        <div className={styles.dataPointFormat}>
          <div className={styles.dataPointLayer}>{Components}</div>
        </div>
      );
    }
  };

  return handleFormat();
}
