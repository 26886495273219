import React, {
    FunctionComponent,
    useContext,
    useEffect
} from "react";
import { AppStore } from "../../../AppStore";
import { NavigationConsumer, WithNavigation } from "../../../providers/NavigationProvider";
import { WebSocketContext } from "../../../providers/WebSocketProvider";
import { Product } from "../../../services/product/models/Product";
import Body from "./Body";

interface Props {}

const Map: FunctionComponent<Props> = (props) => {

  const [productState, productActions] = AppStore.product.use()

  useEffect(() => {
    productActions.setHighlightedProduct(undefined)
  }, [])


  return (
      <Body />
  );
};

export default WithNavigation(Map);
