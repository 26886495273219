import React, {
  FunctionComponent, useContext, useEffect
} from "react";
import { RouteProps } from "react-router-dom";
import { AppStore } from "../../../AppStore";
import { WithNavigation } from "../../../providers/NavigationProvider";
import { Product } from "../../../services/product/models/Product";
import Body from "./Body";

interface Props extends RouteProps {
}

const Dashboard: FunctionComponent<Props> = (props) => {

  const [state, actions] = AppStore.dashboard.use()
  const [productState, productActions] = AppStore.product.use()

  useEffect(() => {

    if (productState.highlightedProduct)
      actions.setProduct(productState.highlightedProduct)

  }, [productState.highlightedProduct])

  return (
      <Body />
  );
};

export default WithNavigation(Dashboard);
