import { Typography } from "@mui/material";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { SyncLoader } from "react-spinners";
import { DeviceAbbreviation, DeviceSensor, Sensor, SensorAbbreviation } from "../../../../services/device/models/Device";
import { PAM } from "../../../../services/device/models/PAM";
import {
  Product, ProductAbbreviated, SimpleProduct,
} from "../../../../services/product/models/Product";
import {
  DeviceWrapper,
  Measurement,
  SensorMeasurement,
  SimpleProductUpload,
} from "../../../../services/product/models/ProductUpload";
import { GridContext } from "../../layouts/GridManager";
import styles from "../../styles/Components.css";

interface Props {
  sensor: DeviceSensor;
}

interface SensorMeasurementProps {
  name: string
  measurement?: SensorMeasurement;
}

const color = "#220088";

const SensorDatapointCard: FunctionComponent<Props> = (props) => {
  const [sensorMeasurement, setSensorMeasurement] =
    useState<JSX.Element | undefined>();
  const { product } = useContext(GridContext);

  const mapDeviceMeasurements = (product: Product) => {
    
    const filtered = ((product as SimpleProduct).lastUpload as SimpleProductUpload)?.points[props.sensor]

    if (filtered) {

      const measurement = (
        <SensorMeasurementComponent 
          name={props.sensor}
          measurement={filtered}
        />
      )

      setSensorMeasurement(measurement);
    } else {

      setSensorMeasurement(undefined);
    }
  };

  useEffect(() => {
    if (product) mapDeviceMeasurements(product);
  }, [(product as SimpleProduct)?.lastUpload]);

  const enabled = !(product?.type == ProductAbbreviated.PAMStandard && props.sensor == DeviceSensor.Ozone)

  return (
    <div className={styles.dataPointContainer}>
      {withFormatting(sensorMeasurement, enabled)}
      <Typography color={color} fontWeight="bold" variant="body1">
        {enabled && props.sensor}
      </Typography>
    </div>
  );
};

const SensorMeasurementComponent: FunctionComponent<SensorMeasurementProps> = (props) => {
  return (
    <div
      style={{
        height: 120,
        width: 120,
      }}
      className={styles.dataPointCircleContainer}
    >
      <div
        style={{
          borderColor: color,
        }}
        className={styles.dataPointCircle}
      >
        <div className={styles.dataPointValueContainer}>
          <Typography
            style={{
              color: color,
            }}
            variant="body2"
            className={styles.dataPointValue}
          >
            {props.measurement?.value.toString().substring(0, 6)}
            <br />
            {props.measurement?.units ?? ""}
          </Typography>
          <div className={styles.dataPointCircleBreak}>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SensorDatapointCard;

function withFormatting(Component: JSX.Element | undefined, enabled: boolean) {
  const handleFormat = (): JSX.Element | undefined => {

    if (!enabled) {
      return <></>;
    }

    if (!Component) {
      return (
        <div className={styles.dataPointFormat}>
          <SyncLoader loading={true} color={color} />
        </div>
      );
    } else {
      
      return (
        <div className={styles.dataPointFormat}>
          <div className={styles.dataPointLayer}>{Component}</div>
        </div>
      );
    }
  };

  return handleFormat();
}
