import { FormModel } from "../../../utils/FormModel";
import Validation from "../../../utils/Validation";
import { DeviceUpload } from "./DeviceUpload";

export class DeviceCollection {
    public devices: Device[] = []
}

export abstract class Device extends FormModel {

    public id: number = -1;
    public name: string = "";
    public coreId: string = "";
    public serial: string = "";
    public type: string = "";
    public reading: string = "";
    public units: string = "";
    public status: DeviceStatus = DeviceStatus.Loading;
    public lastUpdate: DeviceUpload | undefined = undefined;
    public updatedAt: string = "Waiting for update...";

    public readingToString(): string {
        return `${this.reading} (${this.units})`
    }

    abstract Update(update: DeviceUpload): void

}

export enum DeviceAbbreviation {
    CO2 = "CO2",
    NOx = "NOx",
    CO = "CO & GPS",
    Ozone = "Ozone",
    Particle = "Particle",
    Weather = "Weather"
}

export enum DeviceStatus {
    Healthy = "Healthy",
    Unhealthy = "Unhealthy",
    Moderate = "Moderate",
    Loading = "Loading"
}

export enum Sensor {
    Longitude = "Longitude",
    Latitude = "Latitude",
    CO2 = "CO2",
    NO = "NO",
    NO2 = "NO2", 
    NOx = "NOx",
    Ozone = "Ozone",
    TVOC = "TVOC",
    CO = "CO",
    PM1 = "PM1",
    PM2_5 = "PM2.5",
    PM4 = "PM4",
    PM10 = "PM10",
    WindSpeed = "WindSpeed" ,
    WindDirection = "WindDirection" ,
    Temperature = "Temperature" ,
    RelativeHumidity = "RelativeHumidity" ,
    BarometricPressure = "BarometricPressure",
}

export enum DeviceSensor {
    Longitude = "Longitude",
    Latitude = "Latitude",
    Battery = "Battery",
    CO = "CO",
    CO2 = "CO2", 
    NO2 = "NO2",
    NO = "NO",
    PM1 = "PM1",
    PM2_5 = "PM2.5",
    PM10 = "PM10",
    Press = "Press",
    RHum = "RHum",
    Temp = "Temp",
    Ozone = "Ozone"
}

export const SensorWithUnits: Record<Sensor, string>  = {
    "Longitude": "Longitude",
    "Latitude": "Latitude",
    "CO2": "CO2 (ppm)",
    "NO": "NO (ppb)",
    "NO2":  "NO2 (ppb)", 
    "NOx": "NOx (ppb)",
    "Ozone": "Ozone (ppb)",
    "TVOC": "TVOC (ppb)",
    "CO": "CO (ppm)",
    "PM1": "PM1 (ug/m3)",
    "PM2.5": "PM2.5 (ug/m3)",
    "PM4": "PM4 (ug/m3)",
    "PM10": "PM10 (ug/m3)",
    "WindSpeed": "WindSpeed (m/s)",
    "WindDirection": "WindDirection (deg)",
    "Temperature": "Temperature (C)",
    "RelativeHumidity": "RelativeHumidity (%)",
    "BarometricPressure": "BarometricPressure (hPA)"
}

export const DeviceSensorWithUnits: Record<DeviceSensor, string>  = {
    "Longitude": "longitude",
    "Latitude": "latitude",
    "Battery": "Battery (perc)",
    "CO": "CO (ppm)",
    "CO2":  "CO2 (ppm)", 
    "NO2": "NO2 (ppb)",
    "PM1": "PM1 (ug/m3)",
    "PM2.5": "PM2.5 (ug/m3)",
    "PM10": "PM10 (ug/m3)",
    "Press": "Press (mbar)",
    "RHum": "RHum (perc)",
    "Temp": "Temp (c)",
    "Ozone": "Ozone (ppb)",
    "NO": "NO (ppb)",
}

export const SensorAbbreviation: Record<Sensor, string>  = {
    "Longitude": "Long",
    "Latitude": "Lat",
    "CO2": "CO2",
    "NO": "NO",
    "NO2":  "NO2", 
    "NOx": "NOx",
    "Ozone": "Ozone",
    "TVOC": "TVOC",
    "CO": "CO",
    "PM1": "PM1",
    "PM2.5": "PM2.5",
    "PM4": "PM4",
    "PM10": "PM10",
    "WindSpeed": "WS",
    "WindDirection": "WD",
    "Temperature": "Temp",
    "RelativeHumidity": "RH",
    "BarometricPressure": "BP"
}