// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".x30V0pM_UswPnBVqlpfF {\n    height: 100%;\n    width: 250px;\n    /* margin-left: 20px;\n    margin-right: 20px; */\n}\n\n.fCMyTXSNH_aKUBbQer9a {\n    margin-bottom: 20px;\n}\n\n.QoUYY1Ac_hvVYjruBcq_ {\n    width: 100%;\n    margin-top: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n}", "",{"version":3,"sources":["webpack://./src/areas/company/styles/Forms.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ;yBACqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":[".addUserFormContainer {\n    height: 100%;\n    width: 250px;\n    /* margin-left: 20px;\n    margin-right: 20px; */\n}\n\n.textField {\n    margin-bottom: 20px;\n}\n\n.buttonContainer {\n    width: 100%;\n    margin-top: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addUserFormContainer": "x30V0pM_UswPnBVqlpfF",
	"textField": "fCMyTXSNH_aKUBbQer9a",
	"buttonContainer": "QoUYY1Ac_hvVYjruBcq_"
};
export default ___CSS_LOADER_EXPORT___;
