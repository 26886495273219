import { Button, TextField } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import Loader from "react-spinners/BarLoader";
import { AppStore } from "../../../AppStore";
import { downloadCSV } from "../../../helpers/file";
import {
  DeviceSensor,
  DeviceSensorWithUnits,
  Sensor,
  SensorWithUnits
} from "../../../services/device/models/Device";
import { AQSync } from "../../../services/product/models/AQSync";
import { Upload } from "../../../services/product/models/ProductUpload";
import {
  ProductService,
  ProductUtility
} from "../../../services/product/ProductService";
import styles from "../styles/Components.css";

interface Props {
  onDismiss: () => void;
}

const Download: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.dashboard.use();
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [downloading, setDownloading] = useState(false);

  const handleFailedDownload = (error) => {
    console.log(error)
    props.onDismiss()
  }

  const handleFinishDownloading = () => {
    setDownloading(false)
  }

  const handlePreprocessData = (uploads) => {

    let processed: Upload[] = []

    if (state.product instanceof AQSync)
      processed = ProductUtility.preProcessDetailedDownloadData(uploads)
    else
      processed = ProductUtility.preProcessSimpleDownloadData(uploads)

    return processed
  }

  const handleDownload = () => {
    if (start == "" || end == "") return;

    setDownloading(true);

    const product = state.product;
    const startDate = new Date(Date.parse(start));
    const endDate = new Date(Date.parse(end));

    if (product)
      ProductService.getProductUploads(product, startDate, endDate)
        .then(handlePreprocessData)
        .then(downloadToCSV)
        .then(handleFinishDownloading)
        .catch(handleFailedDownload);
  
  };

  const downloadToCSV = (uploads) => {

    const keys: string[] = [];

    keys.push(
      "id",
      "product_serial",
      "device_timestamp",
      "server_timestamp",
      "upload_ip"
    );

    if (state.product instanceof AQSync)
      for (const sensor in Sensor) keys.push(SensorWithUnits[Sensor[sensor]]);
    else
      for (const sensor in DeviceSensor) keys.push(DeviceSensorWithUnits[DeviceSensor[sensor]]);


    const options = {
      emptyFieldValue: 0,
      keys: keys,
    };

    downloadCSV(
      state.product ? state.product.serial : "product", 
      uploads, 
      options)
  };

  return (
    <form className={styles.downloadContainer} noValidate>
      <div className={styles.downloadRangeContainer}>
        <TextField
          id="Start_date"
          label="Start Date"
          type="date"
          value={start}
          onChange={(value) => setStart(value.target.value)}
          className={styles.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          id="End_date"
          label="End Date"
          type="date"
          value={end}
          onChange={(value) => setEnd(value.target.value)}
          className={styles.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div className={styles.downloadButtonContainer}>
        {downloading ? (
          <Loader />
        ) : (
          <>
            <Button onClick={props.onDismiss}>Cancel</Button>
            <Button onClick={handleDownload}>Download</Button>
          </>
        )}
      </div>
    </form>
  );
};

export default Download;
