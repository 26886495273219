import { Typography } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../AppStore";
import Popup from "../../../components/popup/Popup";
import Download from "../components/Download";
import styles from "../styles/Dashboard.css";
import GridManager from "./GridManager";


interface Props {}

const Body: FunctionComponent<Props> = (props) => {
  const [state, actions] = AppStore.dashboard.use();
  const [drawerState, drawerActions] = AppStore.drawer.use();
  const [animationsBuffered, setAnimationsBuffered] = useState(true);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  useEffect(() => {
    setTimeout(() => setAnimationsBuffered(false), 400);
  }, []);

  const handleToggleDownloadModal = (toggle) => {
    setShowDownloadModal(toggle)
  }

  const handleCloseDownloadModal = () => {
    setShowDownloadModal(false);
  };
  
  return (
    <div
      className={`${styles.body}  ${animationsBuffered ? styles.preload : ""} ${
        drawerState.drawerToggled ? styles.openBody : styles.closeBody
      } `}
    >
      <Popup
        title={`Product ${state.product?.serial}`}
        subtitle="Select a date range (in GMT) and download your data."
        visible={showDownloadModal}
        onDismiss={handleCloseDownloadModal}
      >
        <Download onDismiss={handleCloseDownloadModal} />
      </Popup>
        {state.product ? (
          <GridManager 
            onToggleDownloadModal={handleToggleDownloadModal}
          />
        ) : (
          <div className={styles.noProductContainer}>
            <Typography variant="h3">Please select a product</Typography>
          </div>
        )}
    </div>
  );
};

export default Body;
  