import React from "react";
import { DeviceSensor } from "../../../../services/device/models/Device";
import DeviceLocationCard from "../../components/grid/DeviceLocationCard";
import MiniMapWrapper from "../../components/grid/MiniMap";
import GridNavigationBar from "../../components/grid/Navbar";
import ProductGraph from "../../components/grid/ProductGraph";
import { ProductStatusSummary } from "../../components/grid/ProductStatus";
import SensorDatapointCard from "../../components/grid/SensorDatapointCard";

export const SimpleProductGrid = {
    itemCount: 15,
    components: [
      <GridNavigationBar/>,
      <ProductStatusSummary />,
      <ProductGraph />,
      <DeviceLocationCard />,
      <SensorDatapointCard sensor={DeviceSensor.CO} />,
      <SensorDatapointCard sensor={DeviceSensor.CO2} />,
      <SensorDatapointCard sensor={DeviceSensor.NO2} />,
      <SensorDatapointCard sensor={DeviceSensor.PM1} />,
      <SensorDatapointCard sensor={DeviceSensor.PM2_5} />,
      <SensorDatapointCard sensor={DeviceSensor.PM10} />,
      <SensorDatapointCard sensor={DeviceSensor.Press} />,
      <SensorDatapointCard sensor={DeviceSensor.RHum} />,
      <SensorDatapointCard sensor={DeviceSensor.Temp} />,
      <SensorDatapointCard sensor={DeviceSensor.Ozone} />,
      <MiniMapWrapper loading={false} />,
    ],
    layout: [
      {
        x: 0,
        y: 0,
        w: 12,
        h: 2,
        i: "0",
        static: true,
      },
      {
        x: 0,
        y: 2,
        w: 4,
        h: 12,
        i: "1",
        static: true,
      },
      {
        x: 5,
        y: 2,
        w: 8,
        h: 12,
        i: "2",
        static: true,
      },
      {
        x: 0,
        y: 14,
        w: 4,
        h: 5,
        i: "3",
        static: true,
      },
      {
        x: 4,
        y: 14,
        w: 2,
        h: 5,
        i: "4",
        static: true,
      },
      {
        x: 6,
        y: 14,
        w: 2,
        h: 5,
        i: "5",
        static: true,
      },
      {
        x: 8,
        y: 14,
        w: 2,
        h: 5,
        i: "6",
        static: true,
      },
      {
        x: 10,
        y: 14,
        w: 2,
        h: 5,
        i: "7",
        static: true,
      },
      {
        x: 0,
        y: 19,
        w: 2,
        h: 5,
        i: "8",
        static: true,
      },
      {
        x: 2,
        y: 19,
        w: 2,
        h: 5,
        i: "9",
        static: true,
      },
      {
        x: 4,
        y: 19,
        w: 2,
        h: 5,
        i: "10",
        static: true,
      },
      {
        x: 6,
        y: 19,
        w: 2,
        h: 5,
        i: "11",
        static: true,
      },
      {
        x: 8,
        y: 19,
        w: 2,
        h: 5,
        i: "12",
        static: true,
      },
      {
        x: 10,
        y: 19,
        w: 2,
        h: 5,
        i: "13",
        static: true,
      },
      {
        x: 0,
        y: 24,
        w: 12,
        h: 10,
        i: "14",
        static: true,
      },
    ],
  };