import { FormModel } from "../../../utils/FormModel";

export class DeviceUpload extends FormModel {

    public id: number = -1;
    public deviceTime: string = "";
    public serverTime: string = "";
    public location: Location = new Location();
    public points: DataPoints = new DataPoints();
    public status: string = "";
    public serial: string = "";

}

export class Location extends FormModel {

    public latitude: number = 0;
    public longitude: number = 0;
    public accuracy: number = 0;

}

export class DataPoints extends FormModel {

}
