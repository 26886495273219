import { BaseStore } from "../../utils/BaseStore";
import { Device as DeviceModel } from '../../services/device/models/Device'
import { load } from "dotenv";
import { Product } from "../../services/product/models/Product";


export interface DeviceState {
    devices: DeviceModel[]
    highlightedDevice: DeviceModel | undefined
    failedToLoadDevices: boolean
    selectedProduct: Product | undefined;
}

export interface DeviceActions {
    setDevices(prodcts: DeviceModel[]): void
    setHighlightedDevice(product: DeviceModel | undefined): void
    setFailedToLoadDevices(failed: boolean): void
    setSelectedProduct(product: Product): void
}

export class DeviceStore 
    extends BaseStore<DeviceState, DeviceActions> 
    implements DeviceActions {

    protected prototype = DeviceStore.prototype;
    protected initialState = {
        devices: [],
        highlightedDevice: undefined,
        failedToLoadDevices: false,
        selectedProduct: undefined
    }

    constructor() {
        super();        
    }

    public setDevices(devices: DeviceModel[]) {
        this.setState({
            ...this.state,
            devices: devices
        })
    }

    public setHighlightedDevice(product: DeviceModel) {
        this.setState({
            ...this.state,
           highlightedDevice: product
        })
    }

    public setFailedToLoadDevices(failed: boolean) {
        this.setState({
            ...this.state,
           failedToLoadDevices: failed
        })
    }


    public setSelectedProduct(product: Product) {
        this.setState({
            ...this.state,
           selectedProduct: product
        })
    }
}