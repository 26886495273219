import { Typography } from "@mui/material";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import {
  Product,

  ProductStatus
} from "../../../../services/product/models/Product";
import { GridContext } from "../../layouts/GridManager";
import styles from "../../styles/Components.css";

interface Props {}

const ProductStatusSummary: FunctionComponent<Props> = (props) => {
  const [pulse, setPulse] = useState(false);
  const [delayedPulse, setDelayedPulse] = useState(false);
  const { product } = useContext(GridContext)
  const [previousProduct, setPreviousProduct] = useState<Product | undefined>(product)

  const handlePulse = (update) => {

      setPulse(true);
      setDelayedPulse(true);
      setTimeout(() => setPulse(false), 600);
      setTimeout(() => setDelayedPulse(false), 1000);
  };

  useEffect(() => {
    if (product && (product == previousProduct
      || product == undefined)
    ) {
      handlePulse(product.formattedUpdatedAt);
    } else {
      setPreviousProduct(product)
    }
  }, [product?.formattedUpdatedAt]);

  return (
    <div className={styles.productStatusSummaryContainer}>
      <div className={`${styles.productStatusOutline} ${delayedPulse ? styles.delayPulse : ""}`}>
        <div className={`${styles.productStatusInternalOutline} ${delayedPulse ? styles.delayPulse : ""}`}>
          <StatusObject
            status={product?.status ?? ProductStatus.Loading}
            className={`${styles.productStatus} 
          ${pulse ? styles.pulse : ""}`}
          >
            <Typography
              className={styles.productStatusTitle}
              variant="subtitle1"
            >
              Status
            </Typography>
            <Typography className={styles.productStatusTitle} variant="h5">
              {product ? product.status : "Loading..."}
            </Typography>
          </StatusObject>
        </div>
      </div>
      <StatusReason product={product} />
    </div>
  );
};

interface StatusObjectProps {
  status: string;
  className: string;
}

const StatusObject: FunctionComponent<StatusObjectProps> = (props) => {
  return <div {...props} />;
};

interface StatusReasonProps {
  product?: Product
}

const StatusReason: FunctionComponent<StatusReasonProps> = props => {

  return (
    <div className={styles.statusReasonContainer}>
      { props.product?.status != ProductStatus.Healthy 
      ?
      <>
        <div className={styles.statusReasonIcon} >!</div>
        <Typography variant="body1" >{props.product?.statusReason}</Typography>
      </>
      :
        <Typography variant="body1" >Device operating as expected</Typography>
      }
      
    </div>
  )
}

export { ProductStatusSummary, StatusObject };
