import { isUTCOffset } from "../../../helpers/time";
import { FormModel } from "../../../utils/FormModel";
import { Location } from "../../device/models/DeviceUpload";
import { Coordinate } from "./Coordinate";
import {
  DetailedProductUpload,
  SimpleProductUpload,
  Upload,
} from "./ProductUpload";

export interface SimpleProduct extends Product {
  lastUpload: SimpleProductUpload | undefined;
}

export function isSimpleProduct(object: any): object is SimpleProduct {
  return (
    "lastUpload" in object &&
    object["lastUpload"] instanceof SimpleProductUpload
  );
}

export interface DetailedProduct extends Product {
  lastUpload: DetailedProductUpload | undefined;
}

export function isDetailedProduct(object: any): object is DetailedProduct {
  return (
    "lastUpload" in object &&
    object["lastUpload"] instanceof DetailedProductUpload
  );
}

export abstract class Product extends FormModel {
  public id: number = -1;
  public companyId: string = "";
  public stationId: string = "";
  public serial: string = "";
  public status: ProductStatus = ProductStatus.Loading;
  public statusReason: string = "";
  public type: ProductAbbreviated = ProductAbbreviated.All;
  public coreId: string = "";
  public company: string = "";
  public station: string = "";
  public treks: string = "";
  public uploads: string = "";
  public createdAt: string = "";
  public updatedAt: Date | undefined = undefined;
  public formattedUpdatedAt: string = "Waiting for update...";
  public coordinate: Coordinate = new Coordinate(0, 0);

  abstract Update(update: Upload): void;

  protected updateTime(dateString: string) {
    const deviceDate = Date.parse(dateString);
    const date = new Date(deviceDate);

    console.log(date)

    if (!isUTCOffset(dateString)) {
      date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
    }

    const [month, day, year] = [
      ("0" + (date.getMonth() + 1).toString()).slice(-2),
      ("0" + date.getDate()).slice(-2),
      ("0" + date.getFullYear()).slice(-2),
    ];
    const [hour, minutes, seconds] = [
      ("0" + date.getHours()).slice(-2),
      ("0" + date.getMinutes()).slice(-2),
      ("0" + date.getSeconds()).slice(-2),
    ];

    this.formattedUpdatedAt =
      month +
      "/" +
      day +
      "/" +
      year +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds;
  }

  protected handleHealth(
    dateString: string,
    expiration: ProductUploadExpiration
  ) {
    const deviceDate = Date.parse(dateString);
    const date = new Date(deviceDate);

    if (!isUTCOffset(dateString)) {
      date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
    }

    const timeDifference = Math.abs(date.getTime() - Date.now());
    if (timeDifference > ProductUploadExpiration.MAX) {
      this.status = ProductStatus.Unhealthy;
      this.statusReason = "Unsyncronized device time or device is not publishing";
    }

    if (this.updatedAt) {
      const maxDifference = Math.abs(date.getTime() - this.updatedAt.getTime());
      if (maxDifference > expiration) {
        this.status = ProductStatus.Unhealthy;
        this.statusReason = "Upload frequency above threshold";
      }
    }

    this.updatedAt = date;
  }
}

export enum ProductUploadExpiration {
  AQSYNC = 5 * 60 * 1000 + 100, // 5 minutes
  AQLITE = 10 * 60 * 1000 + 100, // 10 minutes
  PAM_PRODUCT = 10 * 60 * 1000 + 100, // 10 minutes
  MAX = 30 * 60 * 1000 + 100 // 30 minutes
}

export enum ProductStatus {
  Healthy = "Healthy",
  Unhealthy = "Unhealthy",
  Moderate = "Moderate",
  Loading = "Loading",
}

export enum ProductAbbreviated {
  AQSync = "AQSync",
  AQLite = "AQLite",
  PAMStandard = "PAM Standard",
  All = "All",
}

export enum ProductType {
  AirQualitySynchronizer = "Air Quality Synchronizer",
  AirQualitySynchronizerLite = "Air Quality Synchronizer Lite",
  PersonalAirMonitor = "Personal Air Monitor",
}

export const ProductTranslation: Record<ProductType, ProductAbbreviated> = {
  "Air Quality Synchronizer": ProductAbbreviated.AQSync,
  "Air Quality Synchronizer Lite": ProductAbbreviated.AQLite,
  "Personal Air Monitor": ProductAbbreviated.PAMStandard,
};

export const Products = [
  ProductType.AirQualitySynchronizer,
  ProductType.AirQualitySynchronizerLite,
  ProductType.PersonalAirMonitor,
];
