// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pdhYw31SyiB5sMuEg7do {\n    max-width: none !important;\n}", "",{"version":3,"sources":["webpack://./src/components/popup/styles/Popup.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B","sourcesContent":[".container {\n    max-width: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "pdhYw31SyiB5sMuEg7do"
};
export default ___CSS_LOADER_EXPORT___;
