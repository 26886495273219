import { Button, Typography } from "@mui/material";
import React, { FunctionComponent, useState } from "react";
import styles from "../styles/Components.css";
import Loader from "react-spinners/BarLoader";
import { AccountService } from "../../../services/account/AccountService";

interface Props {
  onClose: (refresh: boolean) => void;
  selectedUserIds: any[];
}

const ConfirmDeleteModal: FunctionComponent<Props> = (props) => {
  const [confirming, setConfirming] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);

  const handleFailedToRemoveUsers = (reason) => {
    console.log(reason);
    setConfirming(false);
    setSubmitFailed(true);
  };

  const handleConfirmRemove = () => {
    setConfirming(true);
    if (props.selectedUserIds.length > 0) {
      AccountService.removeUsers(props.selectedUserIds)
        .then(() => props.onClose(true))
        .catch(handleFailedToRemoveUsers);
    }
  };

  return (
    <div className={styles.confirmDeleteModalContainer}>
      <Typography variant="subtitle2">
        Are you sure you would like to remove these users?
      </Typography>
      {submitFailed && (
        <Typography paddingTop={"20px"} align="center" color="error" variant="body2">
          Failed to remove users
        </Typography>
      )}
      <div className={styles.confirmDeleteModalButtons}>
        {confirming ? (
          <Loader />
        ) : (
          <>
            <Button variant="text" color="error" onClick={handleConfirmRemove}>
              Confirm
            </Button>
            <Button variant="text" onClick={() => props.onClose(false)}>
              Cancel
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
