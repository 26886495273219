import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../AppStore";
import { AccountService } from "../../../services/account/AccountService";
import { UserDetails } from "../../../services/account/models/UserDetails";
import styles from "../styles/Forms.css";
import Loader from "react-spinners/BarLoader";
import { AddShoppingCartSharp } from "@mui/icons-material";

interface Props {
  onClose: (refresh: boolean) => void;
}

const AddUserForm: FunctionComponent<Props> = (props) => {
  const [adding, setAdding] = useState(false);
  const [accountState, accountActions] = AppStore.account.use();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [admin, setAdmin] = useState(false);
  const [validName, setValidName] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);

  const handleFailedToAddUser = (error) => {
    console.log(error);
    setSubmitFailed(true);
    setAdding(false);
  };

  const handleSubmit = () => {
    setAdding(true);
    if (validName && validEmail && accountState.user?.companyId) {
      const user = new UserDetails();

      user.companyId = accountState.user.companyId;
      user.name = name;
      user.email = email;
      user.isAdmin = admin;

      AccountService.addUser(user)
        .then((respons) => handleClose(true))
        .catch(handleFailedToAddUser);
    }
  };

  const handleClose = (refresh: boolean) => {
    props.onClose(refresh);
  };

  useEffect(() => {
    if (name.length < 4) {
      setValidName(false);
    } else {
      setValidName(true);
    }
  }, [name]);

  useEffect(() => {
    if (email.length < 4 || !email.includes("@")) {
      setValidEmail(false);
    } else {
      setValidEmail(true);
    }
  }, [email]);

  return (
    <div className={styles.addUserFormContainer}>
      <div className={styles.textField}>
        <TextField
          onChange={(event) => setName(event.target.value)}
          required
          label="Name"
          variant="standard"
          fullWidth
          error={!validName && name.length > 0}
        />
      </div>
      <div className={styles.textField}>
        <TextField
          onChange={(event) => setEmail(event.target.value)}
          type="email"
          required
          label="Email"
          variant="standard"
          fullWidth
          error={!validEmail && email.length > 0}
        />
      </div>
      <FormGroup>
        <FormControlLabel
          onChange={(_, checked) => setAdmin(checked)}
          control={<Checkbox />}
          label="Administrator"
        />
      </FormGroup>
      {submitFailed && (
        <Typography paddingTop={"20px"} align="center" color="error" variant="body2">
          Failed to add user
        </Typography>
      )}

      <div className={styles.buttonContainer}>
        {adding ? (
          <Loader />
        ) : (
          <>
            <Button variant="text" onClick={handleSubmit}>
              Add
            </Button>
            <Button variant="text" onClick={() => handleClose(false)}>
              Cancel
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default AddUserForm;
