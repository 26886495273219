import { Button, Typography } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AppStore } from "../../../AppStore";
import Popup from "../../../components/popup/Popup";
import { CompanyService } from "../../../services/company/CompanyService";
import UserTableUtility from "../components/UserTableUtility";
import UserTable from "../components/UserTable";
import styles from "../styles/Company.css";
import { UserDetails } from "../../../services/account/models/UserDetails";

interface Props {}

const Body: FunctionComponent<Props> = (props) => {
  const [accountStore, accountActions] = AppStore.account.use();
  const [drawerState, drawerActions] = AppStore.drawer.use();
  const [productState, productActions] = AppStore.product.use();
  const [animationsBuffered, setAnimationsBuffered] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [users, setUsers] = useState<UserDetails[]>([]);

  const handleUsers = (users) => {
    setUsers(users)
  };

  const handleFailedToGetUsers = (error) => {
    console.log("Failed to retrieve users...");
  };

  const handleRefresh = () => {
    setRefresh(true)
  }

  useEffect(() => {
    setTimeout(() => setAnimationsBuffered(false), 400);
  }, []);

  useEffect(() => {
    if (accountStore.user?.companyId && refresh) {
      CompanyService.getUsers(accountStore.user.companyId)
        .then(handleUsers)
        .catch(handleFailedToGetUsers)
      setRefresh(false)
    }
  }, [refresh]);

  return (
    <div
      className={`${styles.body}  ${animationsBuffered ? styles.preload : ""} ${
        drawerState.drawerToggled ? styles.openBody : styles.closeBody
      } `}
    >
      <div className={styles.bodyContent}>
        <UserTable
          addControls={accountStore.user?.isAdmin}
          users={users}
          onRefresh={handleRefresh}
        />
      </div>
    </div>
  );
};

export default Body;
