import { isUTCOffset } from "../../../helpers/time";
import { DeviceAbbreviation } from "../../device/models/Device";
import { Coordinate } from "./Coordinate";
import { DetailedProduct, Product, ProductAbbreviated, ProductStatus, ProductUploadExpiration } from "./Product";
import {
  DetailedProductUpload,
  Measurement,
  ProductData,
} from "./ProductUpload";

export class AQSync extends Product implements DetailedProduct {
  constructor() {
    super();

    this.type = ProductAbbreviated.AQSync
  }

  lastUpload: DetailedProductUpload | undefined;

  static devices = [
    DeviceAbbreviation.CO2,
    DeviceAbbreviation.NOx,
    DeviceAbbreviation.CO,
    DeviceAbbreviation.Ozone,
    DeviceAbbreviation.Particle,
    DeviceAbbreviation.Weather,
  ];

  public Update(update: DetailedProductUpload): void {
    this.status = update.status
      ? ProductStatus[update.status]
      : ProductStatus.Loading;

    const deviceData = update.data;

    super.updateTime(update.deviceTime);

    if (this.isMissingDevices(deviceData)) {
      this.status = ProductStatus.Moderate;
      this.statusReason = "One or more devices not publishing"
    }
    super.handleHealth(update.deviceTime, ProductUploadExpiration.AQSYNC);

    if (this.ContainsPAM(deviceData))
      this.HandleCoordinates(deviceData.CO.measurements);

    this.lastUpload = update;
  }

  private HandleCoordinates(measurements: Measurement[]) {
    let [x, y] = [0, 0];

    measurements.forEach((measurement) => {
      if (measurement["sensor"] == "Latitude") x = measurement["reading"];

      if (measurement["sensor"] == "Longitude") y = measurement["reading"];
    });

    this.coordinate = new Coordinate(x, y);
  }

  private ContainsPAM(data: ProductData): boolean {
    const key =
      Object.keys(DeviceAbbreviation).find(
        (key) => DeviceAbbreviation[key] === DeviceAbbreviation.CO
      ) ?? "";

    return data[key] != undefined;
  }

  private isMissingDevices(data: ProductData): boolean {
    let missing = false;

    AQSync.devices.forEach((device) => {
      const key = Object.keys(DeviceAbbreviation).find(
        (key) => DeviceAbbreviation[key] === device
      );

      if (data[key ?? ""] == undefined) missing = true;
    });

    return missing;
  }
}
