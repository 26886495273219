import React from "react";
import { DeviceAbbreviation } from "../../../../services/device/models/Device";
import MiniMapWrapper from "../../components/grid/MiniMap";
import GridNavigationBar from "../../components/grid/Navbar";
import AQSyncDatapoint from "../../components/grid/ProductDatapointCard";
import ProductGraph from "../../components/grid/ProductGraph";
import { ProductStatusSummary } from "../../components/grid/ProductStatus";

export const DetailedProductGrid = {
    itemCount: 10,
    components: [
      <GridNavigationBar/>,
      <ProductStatusSummary />,
      <ProductGraph />,
      <AQSyncDatapoint device={DeviceAbbreviation.NOx} />,
      <AQSyncDatapoint device={DeviceAbbreviation.Ozone} />,
      <AQSyncDatapoint device={DeviceAbbreviation.CO} />,
      <AQSyncDatapoint device={DeviceAbbreviation.Particle} />,
      <AQSyncDatapoint device={DeviceAbbreviation.Weather} />,
      <AQSyncDatapoint device={DeviceAbbreviation.CO2} />,
      <MiniMapWrapper loading={false} />,
    ],
    layout: [
      {
        x: 0,
        y: 0,
        w: 12,
        h: 2,
        i: "0",
        static: true,
      },
      {
        x: 0,
        y: 2,
        w: 4,
        h: 12,
        i: "1",
        static: true,
      },
      {
        x: 5,
        y: 2,
        w: 8,
        h: 12,
        i: "2",
        static: true,
      },
      {
        x: 0,
        y: 14,
        w: 2,
        h: 8,
        i: "3",
        static: true,
      },
      {
        x: 2,
        y: 14,
        w: 2,
        h: 8,
        i: "4",
        static: true,
      },
      {
        x: 4,
        y: 14,
        w: 2,
        h: 8,
        i: "5",
        static: true,
      },
      {
        x: 6,
        y: 14,
        w: 2,
        h: 8,
        i: "6",
        static: true,
      },
      {
        x: 8,
        y: 14,
        w: 2,
        h: 8,
        i: "7",
        static: true,
      },
      {
        x: 10,
        y: 14,
        w: 2,
        h: 8,
        i: "8",
        static: true,
      },
      {
        x: 0,
        y: 22,
        w: 12,
        h: 15,
        i: "9",
        static: true,
      },
    ],
  };
  