import { Drawer } from "@mui/material";
import React, { FunctionComponent } from "react";
import { NavigationConsumer, WithNavigation } from "../../../providers/NavigationProvider";
import Body from "./Body";

interface Props {}

const Company: FunctionComponent<Props> = props => {

    return (
        <Body />
    )
}

export default WithNavigation(Company);