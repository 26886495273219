import { Button } from "@mui/material";
import { LatLng } from "leaflet";
import React, {
  createRef,
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from "react";
import { CircleMarker, Popup } from "react-leaflet";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../App";
import { AppStore } from "../../../AppStore";
import {
  Product,
  ProductStatus
} from "../../../services/product/models/Product";
import styles from "../styles/Map.css";

interface Props {
  product: Product;
  visual?: boolean;
}

const ProductMapPoint: FunctionComponent<Props> = (props) => {
  const history = useHistory();
  const [state, actions] = AppStore.map.use();
  const [productState, productActions] = AppStore.product.use()
  const { product } = props;
  const [color, setColor] = useState("grey")
  const coordinate = new LatLng(product.coordinate.X, product.coordinate.Y);
  const toDashboard = () => history.push(Routes.Dashboard);
  const ref = createRef<any>();
  const [pulsed, setPulsed] = useState(false);

  const handleOpenPopup = () => {
    if (!props.visual) {
      productActions.setHighlightedProduct(product);
    }
  };

  const handleClosePopup = () => {
    if (!props.visual) {
      productActions.setHighlightedProduct(undefined);
    }
  };

  useEffect(() => {
    if (productState.highlightedProduct?.serial == product.serial && !props.visual)
      ref.current.openPopup();
  }, [productState.highlightedProduct]);

  useEffect(() => {
    switch (product.status) {
      case ProductStatus.Healthy:
        setColor("#008000")
        break
      case ProductStatus.Unhealthy:
        setColor("#c80000")
        break
      case ProductStatus.Moderate:
        setColor("#f9e32e")
        break
      default:
        setColor("grey")
    }
  }, [product.status]);

  return (
    <CircleMarker
      center={coordinate}
      ref={ref}
      className={`${styles.marker} ${pulsed ? styles.pulse : ""}`}
      eventHandlers={{
        popupopen: handleOpenPopup,
        popupclose: handleClosePopup,
      }}
      pathOptions={{
        color: color,
        fillOpacity: 1,
      }}
      radius={13}
    >
      <Popup>
        <div className={styles.plotPopUp}>
          <p className={styles.popupFont}>Serial: {product.serial}</p>
          <p className={styles.popupFont}>Status: {product.status}</p>
          <p className={styles.popupFont}>
            Location: X: {product.coordinate.X} Y: {product.coordinate.Y}
          </p>
          <p className={styles.popupFont}>Last Update: {product.formattedUpdatedAt}</p>
        </div>
        <div className={styles.popupButtonContainer}>
          {!props.visual && <Button onClick={toDashboard}>Dashboard</Button>}
        </div>
      </Popup>
    </CircleMarker>
  );
};

export default ProductMapPoint;
