import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Product,
  ProductAbbreviated,
  ProductStatus,
} from "../../../services/product/models/Product";
import { ProductStatus as ProductStatusComponent } from "../../productStatus/ProductStatus";
import styles from "../styles/Drawer.css";
import { AppStore } from "../../../AppStore";
import { PAMProduct } from "../../../services/product/models/PAMProduct";
import { AQSync } from "../../../services/product/models/AQSync";
import { AQLite } from "../../../services/product/models/AQLite";

interface Props {
  products: Array<Product> | undefined;
  noProducts?: string;
  filter: ProductAbbreviated | "";
}

interface ListItemProps {
  product: Product;
  highlighted: boolean;
  filter: ProductAbbreviated | "";
}

const ProductList: FunctionComponent<Props> = (props) => {
  const [products, setProducts] = useState<JSX.Element[] | undefined>([]);
  const [productState, productActions] = AppStore.product.use();

  const mapProducts = () => {
    const filter = props.filter
    const products = props.products
      ?.sort((a, b) => parseInt(a.serial) - parseInt(b.serial))
      ?.filter((product) =>
        filter == "" ? true : product.type == filter
      )
      .map((product, index) => (
        <ProductListItem
          key={index}
          filter={filter}
          highlighted={
            productState.highlightedProduct
              ? productState.highlightedProduct.serial == product.serial
              : false
          }
          product={product}
        />
      ));

    setProducts(products);
  };

  useEffect(() => {
    mapProducts();
  }, [productState.highlightedProduct, props.products, props.filter]);

  return (
    <List>
      {props.products ? (
        products
      ) : (
        <Typography variant="h6">{props.noProducts}</Typography>
      )}
    </List>
  );
};

const ProductListItem: FunctionComponent<ListItemProps> = (props) => {
  const [productState, productActions] = AppStore.product.use();
  const [previousUpdate, setPreviousUpdate] = useState("");
  const { product, highlighted } = props;
  const [pulsed, setPulsed] = useState(false);
  const [image, setImage] = useState("./icons/aqsync-logo.png")

  const handleClick = (product) => {
    productActions.setHighlightedProduct(product);
  };

  const handlePulse = () => {
    setPulsed(true);
    setTimeout(() => setPulsed(false), 600);
  };

  useEffect(() => {
    const update = product.formattedUpdatedAt;

    if (product.status != ProductStatus.Loading && update != previousUpdate)
      handlePulse();

    setPreviousUpdate(update);
  }, [product.formattedUpdatedAt]);

  useEffect(() => {

    if (product instanceof AQSync)
      setImage("./icons/aqsync-logo.png")

    if (product instanceof PAMProduct)
      setImage("./icons/pam-logo.png")
    
    if (product instanceof AQLite)
      setImage("./icons/aqlite-logo.png")

  }, [props.filter])

  return (
    <ListItem
      button
      onClick={() => handleClick(product)}
      className={highlighted ? styles.highlightedListItem : ""}
    >
      <ListItemIcon>
        <img src={image} height={35} width={25} />
      </ListItemIcon>
      <ListItemText
        className={styles.productlistItem}
        primary={product.serial}
        secondary={
          product.formattedUpdatedAt != "Waiting for update..."
            ? `Updated ${product.formattedUpdatedAt}`
            : product.formattedUpdatedAt
        }
      />
      <ProductStatusComponent pulsed={pulsed} status={product.status} />
    </ListItem>
  );
};

export default ProductList;
