import { FormModel } from "../../../utils/FormModel";

export class UserDetails extends FormModel {
    
    public id: number = 0;
    public email: string = "";
    public name: string = "";
    public signupAt: Date = new Date();
    public isAdmin: boolean = false;
    public companyId: string = "";
}
