import { FormModel } from "../../../utils/FormModel";
import { Sensor } from "../../device/models/Device";
import { Coordinate } from "./Coordinate";


export abstract class Upload extends FormModel {

    public id: number = -1;
    public productId: number = -1;
    public deviceTime: string = "";
    public serial: string = "";
    public serverTime: string = "";
    public status: string = "";
    public uploadIP: string = "";

}

export class DetailedProductUpload extends Upload {
    public data: ProductData = new ProductData();
}

export class SimpleProductUpload extends Upload {

    public points: DeviceSensors = new DeviceSensors();
    public location: Coordinate = new Coordinate(0,0);

}

export class ProductData extends FormModel {

    public CO2: DeviceWrapper = new DeviceWrapper()
    public NOx: DeviceWrapper = new DeviceWrapper()
    public Ozone: DeviceWrapper = new DeviceWrapper()
    public CO: DeviceWrapper = new DeviceWrapper()
    public Particle: DeviceWrapper = new DeviceWrapper()
    public Weather: DeviceWrapper = new DeviceWrapper()

}

export class DeviceSensors extends FormModel {
    public battery: SensorMeasurement = new SensorMeasurement("", 0);
    public CO: SensorMeasurement = new SensorMeasurement("", 0);
    public CO2: SensorMeasurement = new SensorMeasurement("", 0);
    public NO2: SensorMeasurement = new SensorMeasurement("", 0);
    public PM1: SensorMeasurement = new SensorMeasurement("", 0);
    public PM2_5: SensorMeasurement = new SensorMeasurement("", 0);
    public PM10: SensorMeasurement = new SensorMeasurement("", 0);
    public Press: SensorMeasurement = new SensorMeasurement("", 0);
    public RHum: SensorMeasurement = new SensorMeasurement("", 0);
    public Temp: SensorMeasurement = new SensorMeasurement("", 0);

}

export class DeviceWrapper extends FormModel {

    public measurements: Measurement[] = []
    public epoch: number = 0;
    public hash: string = "";
    public serialNumber: string = "";    

}

export class Measurement extends FormModel {
    public sensor: Sensor = Sensor.CO2;
    public units: string = "";
    public reading: number = 0;
}

export class SensorMeasurement extends FormModel {
    public units: string = "";
    public value: number = 0;

    constructor(units, value) {
        super();
        this.units = units;
        this.value = value;
    }
}