import { Typography } from "@mui/material";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { SyncLoader } from "react-spinners";
import { Coordinate } from "../../../../services/product/models/Coordinate";
import { PAMProduct } from "../../../../services/product/models/PAMProduct";
import { SimpleProduct } from "../../../../services/product/models/Product";
import {
  SensorMeasurement,
  SimpleProductUpload,
} from "../../../../services/product/models/ProductUpload";
import { GridContext } from "../../layouts/GridManager";
import styles from "../../styles/Components.css";

interface Props {}

interface SensorMeasurementProps {
  name: string;
  measurement?: SensorMeasurement;
}

const color = "#220088";

const DeviceLocationCard: FunctionComponent<Props> = (props) => {
  const { product } = useContext(GridContext);
  const [coordinate, setCoordinate] = useState<Coordinate | undefined>();

  useEffect(() => {
    setCoordinate(
      ((product as SimpleProduct).lastUpload as SimpleProductUpload)?.location
    );
  }, [(product as SimpleProduct)?.lastUpload]);

  return (
    <div className={styles.dataPointContainer}>
      <div className={styles.dataPointFormat}>
        {coordinate ? (
          <div className={styles.dataPointLayer}>
            <SensorMeasurementComponent
              name="Lat"
              measurement={new SensorMeasurement("", coordinate.latitude)}
            />
            <SensorMeasurementComponent
              name="Lon"
              measurement={new SensorMeasurement("", coordinate.longitude)}
            />
          </div>
        ) : (
          <SyncLoader loading={true} color={color} />
        )}
      </div>
      <Typography color={color} fontWeight="bold" variant="body1">
        Location
      </Typography>
    </div>
  );
};

const SensorMeasurementComponent: FunctionComponent<SensorMeasurementProps> = (
  props
) => {
  return (
    <div
      style={{
        height: 120,
        width: 120,
      }}
      className={styles.dataPointCircleContainer}
    >
      <div
        style={{
          borderColor: color,
        }}
        className={styles.dataPointCircle}
      >
        <div className={styles.dataPointValueContainer}>
          <Typography
            style={{
              color: color,
            }}
            variant="body2"
            className={styles.dataPointValue}
          >
            {props.measurement?.value.toString().substring(0, 6)}
            <br />
            {props.measurement?.units ?? ""}
          </Typography>
          <div className={styles.dataPointCircleBreak}>
            <Typography
              style={{
                color: color,
              }}
              variant="body2"
              className={styles.dataPointUnits}
            >
              {props.name}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceLocationCard;
