const version = "v1"

export const API = {

    local: {
        session: "session-token"
    },
    
    webSockets: {
        productUpdates: `${""}/${version}/live/uploads`
    },

    server: {
        healthCheck: `${""}/hc`, // GET

        account: {
            details: `${""}/${version}/users/%email%`, // GET
            list: `${""}/${version}/users/list`, // GET
        },

        auth0: {
            users: `https://cors-anywhere.herokuapp.com/https://${""}/api/v2/users`
        },

        company: {
            users: `${""}/${version}/companies/%id%/users`
        },

        user: {
            add: `${""}/${version}/users/add`,
            removeMany: `${""}/${version}/users/remove`
        },

        password: {
            update: `${""}/${version}/password/update`, // POST
            requestReset: `${""}/${version}/password/reset`, // POST
            verifyReset: `${""}/${version}/password/verify`, // POST
        },

        product: {
            list: `${""}/${version}/products`, // GET
        },

        uploads: {
            recentAQSync: `${""}/${version}/uploads/aqsync/recent`, // GET
            recentAQLite: `${""}/${version}/uploads/aqlite/recent`, // GET
            recentPAM: `${""}/${version}/uploads/pam/recent`, // GET
            streamAQSync: `${""}/${version}/uploads/aqsync/stream`, // GET
            streamPAM: `${""}/${version}/uploads/pam/stream`, // GET
            streamAQLite: `${""}/${version}/uploads/aqlite/stream` // GET
        },

        device: {
            listByProduct: `${""}/${version}/devices/%serial%`, // GET
            recentUploads: `${""}/${version}/uploads/pam/recent` // GET
        }
    }
}