import { DeviceSensor } from "../../device/models/Device";
import { Coordinate } from "./Coordinate";
import {
    Product,
  ProductAbbreviated,
    ProductStatus,
    ProductUploadExpiration,
    SimpleProduct
} from "./Product";
import {
    DeviceSensors, SimpleProductUpload
} from "./ProductUpload";

export class PAMProduct extends Product implements SimpleProduct {
  constructor() {
    super();

    this.type = ProductAbbreviated.PAMStandard

  }

  lastUpload: SimpleProductUpload | undefined;

  static sensors = [
    DeviceSensor.Battery,
    DeviceSensor.CO,
    DeviceSensor.CO2,
    DeviceSensor.NO2,
    DeviceSensor.PM1,
    DeviceSensor.PM2_5,
    DeviceSensor.PM10,
    DeviceSensor.Press,
    DeviceSensor.RHum,
    DeviceSensor.Temp,
  ];

  public Update(update: SimpleProductUpload): void {
    this.status = update.status
      ? ProductStatus[update.status]
      : ProductStatus.Loading;

    super.updateTime(update.deviceTime);

    if (this.isMissingDevices(update.points)) {
      this.status = ProductStatus.Moderate;
      this.statusReason = "One or more devices not publishing"
    }

    super.handleHealth(update.deviceTime, ProductUploadExpiration.PAM_PRODUCT);

    this.lastUpload = update;

    this.HandleCoordinates(update.location);
  }

  private HandleCoordinates(coordinate: Coordinate) {
    let [x, y] = [coordinate.latitude, coordinate.longitude];

    this.coordinate = new Coordinate(x, y);
  }

  private isMissingDevices(data: DeviceSensors): boolean {
    let missing = false;

    PAMProduct.sensors.forEach((sensor) => {
      if (data[sensor ?? ""] == undefined) missing = true;
    });

    return missing;
  }
}
