import { AxiosRequestConfig } from "axios";
import { API } from "../../api";
import { ApiClient } from "../../utils/ApiClient";
import { Device, DeviceAbbreviation } from "./models/Device";
import { DeviceUpload } from "./models/DeviceUpload";
import { PAM } from "./models/PAM";

export class DeviceService {
  public static getDevicesBySerial(serial: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.device.listByProduct.replace("%serial%", serial),
    };

    return ApiClient.request(requestConfig).then((response) => {
      const devices: Device[] = [];
      response.model.forEach((device) => {
        devices.push(
            DeviceUtility.createDeviceByType(device.type).copy(device))
      });
      return devices;
    });
  }

  public static getRecentUploads() {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.device.recentUploads,
    };

    return ApiClient.request(requestConfig).then((response) => {
      const uploads: DeviceUpload[] = [];
      response.model.forEach((model) => {
        uploads.push(new DeviceUpload().copy(model));
      });
      return uploads;
    });
  }
}

export class DeviceUtility {


  public static createDeviceByType(type: string): Device {
    switch (type) {
      case DeviceAbbreviation["CO2"]:
        break;
      case DeviceAbbreviation["CO"]:
        return new PAM();
      case DeviceAbbreviation["NOx"]:
        break;
      default:
        break;
    }
    return new PAM();
  }
}
