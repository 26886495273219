import { AxiosRequestConfig } from "axios";
import { API } from "../../api";
import { ApiClient } from "../../utils/ApiClient";
import { UserDetails } from "../account/models/UserDetails";

export class CompanyService {
  public static getUsers(id: string) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.company.users.replace("%id%", id),
    };

    return ApiClient.request(requestConfig).then((response) => {
      const users: UserDetails[] = [];
      response.model.forEach((model) =>
        users.push(new UserDetails().copy(model))
      );
      return users;
    });
  }
}
