import { AxiosRequestConfig } from "axios";
import { API } from "../../api";
import { isUTCOffset } from "../../helpers/time";
import { ApiClient } from "../../utils/ApiClient";
import { Device } from "../device/models/Device";
import { AQLite } from "./models/AQLite";
import { AQSync } from "./models/AQSync";
import { PAMProduct } from "./models/PAMProduct";

import {
  Product,
  ProductAbbreviated,
  ProductTranslation,
  ProductType,
} from "./models/Product";
import {
  DetailedProductUpload,
  DeviceWrapper,
  SimpleProductUpload,
  Upload,
} from "./models/ProductUpload";

export class ProductService {
  public static getProducts() {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.product.list,
    };

    return ApiClient.request(requestConfig).then((response) => {
      const products: Product[] = [];
      response.model.forEach((model) => {
        products.push(
          ProductUtility.createProductByType(model.type).copy(model)
        );
      });
      return products;
    });
  }

  public static getRecentAQSyncUploads() {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.uploads.recentAQSync,
    };

    return ApiClient.request(requestConfig).then((response) => {
      const uploads: Upload[] = [];
      response.model.forEach((model) => {
        uploads.push(new DetailedProductUpload().copy(model));
      });
      return uploads;
    });
  }

  public static getRecentAQLiteUploads() {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.uploads.recentAQLite,
    };

    return ApiClient.request(requestConfig).then((response) => {
      const uploads: Upload[] = [];
      response.model.forEach((model) => {
        uploads.push(new SimpleProductUpload().copy(model));
      });
      return uploads;
    });
  }

  public static getRecentPAMUploads() {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.uploads.recentPAM,
    };

    return ApiClient.request(requestConfig).then((response) => {
      const uploads: Upload[] = [];
      response.model.forEach((model) => {
        uploads.push(new SimpleProductUpload().copy(model));
      });
      return uploads;
    });
  }

  public static getProductUploads(product: Product, start: Date, end: Date): Promise<any> {
    if (product instanceof PAMProduct)
      return this.getPAMUploads(product.serial, start, end);

    if (product instanceof AQLite)
      return this.getAQLiteUploads(product.serial, start, end);

    return this.getAQSyncUploads(product.serial, start, end);
  }

  public static getAQSyncUploads(serial: string, start: Date, end: Date) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.uploads.streamAQSync,
      params: {
        serial: serial,
        start: start,
        end: end,
      },
    };

    return ApiClient.request(requestConfig).then((response) => {
      const uploads: DetailedProductUpload[] = [];
      response.model.forEach((model) => {
        uploads.push(new DetailedProductUpload().copy(model));
      });
      return uploads;
    });
  }

  public static getAQLiteUploads(serial: string, start: Date, end: Date) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.uploads.streamAQLite,
      params: {
        serial: serial,
        start: start,
        end: end,
      },
    };

    return ApiClient.request(requestConfig).then((response) => {
      const uploads: SimpleProductUpload[] = [];
      response.model.forEach((model) => {
        uploads.push(new SimpleProductUpload().copy(model));
      });
      return uploads;
    });
  }

  public static getPAMUploads(serial: string, start: Date, end: Date) {
    const requestConfig: AxiosRequestConfig = {
      method: "GET",
      url: API.server.uploads.streamPAM,
      params: {
        serial: serial,
        start: start,
        end: end,
      },
    };

    return ApiClient.request(requestConfig).then((response) => {
      const uploads: SimpleProductUpload[] = [];
      response.model.forEach((model) => {
        uploads.push(new SimpleProductUpload().copy(model));
      });
      return uploads;
    });
  }
}

export class ProductUtility {
  public static preProcessDetailedDownloadData(
    uploads: DetailedProductUpload[]
  ): any {
    let data: any[] = [];

    for (const upload of uploads) {
      const flattenedUpload = {};

      flattenedUpload["id"] = upload.id;
      flattenedUpload["server_timestamp"] = upload.serverTime;
      flattenedUpload["device_timestamp"] = upload.deviceTime;
      flattenedUpload["product_serial"] = upload.serial;
      flattenedUpload["upload_ip"] = upload.uploadIP;

      for (const deviceName of Object.keys(upload.data)) {
        for (const measurement of upload.data[deviceName].measurements) {
          const key = measurement["units"]
            ? measurement["sensor"] + ` (${measurement["units"]})`
            : measurement["sensor"];

          flattenedUpload[key] = measurement["reading"];
        }
      }

      data.push(flattenedUpload);
    }

    return data;
  }

  public static preProcessSimpleDownloadData(
    uploads: SimpleProductUpload[]
  ): any {
    let data: any[] = [];
    console.log(uploads);
    for (const upload of uploads) {
      const flattenedUpload = {};

      flattenedUpload["id"] = upload.id;
      flattenedUpload["server_timestamp"] = upload.serverTime;
      flattenedUpload["device_timestamp"] = upload.deviceTime;
      flattenedUpload["product_serial"] = upload.serial;
      flattenedUpload["upload_ip"] = upload.uploadIP;
      flattenedUpload["longitude"] = upload.location.longitude;
      flattenedUpload["latitude"] = upload.location.latitude;

      for (const sensor of Object.keys(upload.points)) {
        const units = upload.points[sensor]["units"];
        const value = upload.points[sensor]["value"];

        flattenedUpload[sensor + ` (${units})`] = value;
      }

      data.push(flattenedUpload);
    }

    return data;
  }

  public static preProcessDetailedGraphData(
    uploads: DetailedProductUpload[]
  ): any {
    let graph = {};

    for (const upload of uploads) {
      const deviceDate = Date.parse(upload.deviceTime);
      const date = new Date(deviceDate);

      if (!isUTCOffset(upload.deviceTime)) {
        date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
      }

      for (const device of Object.keys(upload.data)) {
        for (const measurement of upload.data[device].measurements) {
          if (!graph[measurement["sensor"]]) {
            // create new graphable

            graph[measurement["sensor"]] = {
              sensor: measurement["sensor"],
              units: measurement["units"],
              sample: [measurement["reading"]],
              time: [date],
            };
          } else {
            graph[measurement["sensor"]]["sample"].push(measurement["reading"]);
            graph[measurement["sensor"]]["time"].push(date);
          }
        }
      }
    }

    return graph;
  }

  public static preProcessSimpleGraphData(uploads: SimpleProductUpload[]): any {
    let graph = {};

    for (const upload of uploads) {
      const deviceDate = Date.parse(upload.deviceTime);
      const date = new Date(deviceDate);

      if (!isUTCOffset(upload.deviceTime)) {
        date.setHours(date.getHours() - date.getTimezoneOffset() / 60);
      }

      for (const sensor of Object.keys(upload.points)) {
        if (!graph[sensor]) {
          // create new graphable

          graph[sensor] = {
            sensor: sensor,
            units: upload.points[sensor]["units"],
            sample: [upload.points[sensor]["value"]],
            time: [date],
          };
        } else {
          graph[sensor]["sample"].push(upload.points[sensor]["value"]);
          graph[sensor]["time"].push(date);
        }
      }
    }

    return graph;
  }

  public static createProductByType(type: ProductAbbreviated): Product {
    switch (type) {
      case ProductTranslation[ProductType.AirQualitySynchronizer]:
        return new AQSync();
      case ProductTranslation[ProductType.AirQualitySynchronizerLite]:
        return new AQLite();
      case ProductTranslation[ProductType.PersonalAirMonitor]:
        return new PAMProduct();
      default:
        break;
    }
    return new AQSync();
  }
}
